import _ from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { getAssetApi, GetAssetApiResponseType } from 'src/api/asset/assetApi';
import { getCookie, SERVICE_PATH } from 'src/utils/cookies';
import useManholeStore from 'src/store/ManholeStore';
import useAuthStore from 'src/store/AuthStore';

function useInitTime() {
  const { assets, setAssets, initTime, setInitTime, setUpdateTime } =
    useManholeStore();

  const { loggedIn } = useAuthStore();

  /**
   *  Total Assets Data
   */

  const getAssetsQuery = useQuery<GetAssetApiResponseType>(
    ['assets'],
    () => getAssetApi({ status: '' }),
    {
      onSuccess: (res) => {
        const sortedEventByTimeStamp = _.sortBy(res.events, 'timestamp')
          .reverse()
          .filter((item) => item.timestamp && item.sensor && item.geoLocation);

        if (!_.isEmpty(sortedEventByTimeStamp) && !initTime) {
          setUpdateTime(sortedEventByTimeStamp[0].timestamp);
          setInitTime(true);
        }
        setAssets(sortedEventByTimeStamp);
      },
      enabled: loggedIn && !!getCookie(SERVICE_PATH) && _.isEmpty(assets),
    },
  );

  return {
    getAssetsQuery,
  };
}

export default useInitTime;
