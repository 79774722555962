export interface AwsConfigType {
  region: string;
  cognitoUserPoolId: string;
  cognitoUserPoolClientId: string;
  cognitoIdentityPoolId: string;
  mqttBrokerEndpoint: string;
}

export const awsConfig: AwsConfigType = {
  region: `${window._env_.REACT_APP_AWS_REGION}`,
  cognitoUserPoolId: `${window._env_.REACT_APP_COGNITO_USER_POLL_ID}`,
  cognitoUserPoolClientId: `${window._env_.REACT_APP_COGNITO_APP_CLIENT_ID}`,
  cognitoIdentityPoolId: `${window._env_.REACT_APP_COGNITO_IDENTITY_POOL_ID}`,
  mqttBrokerEndpoint: `${window._env_.REACT_APP_MQTT_BROKER_END_POINT}`,
};
