import _ from 'lodash';
import React, { useMemo } from 'react';
import useManholeStore from 'src/store/ManholeStore';
import { TotalListCountType } from 'src/types/statistics';
import { getCountFromStatus } from 'src/utils/statistics';

function useMain() {
  const { assets } = useManholeStore();

  /**
   * Total count from Status
   */

  const totalListCount = useMemo<TotalListCountType>(() => {
    if (!_.isEmpty(assets)) {
      return {
        ALL: assets.length,
        NORMAL: getCountFromStatus({
          assets,
          status: 'NORMAL',
        }),
        WARNING: getCountFromStatus({
          assets,
          status: 'WARNING',
        }),
        DANGER: getCountFromStatus({
          assets,
          status: 'DANGER',
        }),
        INACTIVE: assets.filter((asset) => asset.active === false).length,
      };
    }
    return {
      ALL: 0,
      NORMAL: 0,
      WARNING: 0,
      DANGER: 0,
      INACTIVE: 0,
    };
  }, [assets]);

  return {
    totalListCount,
  };
}

export default useMain;
