import React, { useCallback } from 'react';
import useManholeStore from 'src/store/ManholeStore';

function useHeader() {
  const { setUnselectedAsset } = useManholeStore();

  const handleClickHeader = useCallback(() => {
    setUnselectedAsset();
  }, [setUnselectedAsset]);

  return { handleClickHeader };
}

export default useHeader;
