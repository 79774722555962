import React, { ReactElement } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Header from '../Header';

interface LayoutPropsType {
  children?: ReactElement;
}

function Layout({ children }: LayoutPropsType) {
  const location = useLocation();

  return (
    <div>
      {!location.pathname.includes('login') && <Header />}
      {children}
      <Outlet />
      <ToastContainer />
    </div>
  );
}

export default Layout;
