import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import DoughnutChart from '../_common/DoughnutChart';

interface StatusChartPropsType {
  chartData: number[];
  title: string;
  tooltipList: any[];
  isTrend: boolean;
}

function StatusChart({
  chartData,
  title,
  tooltipList,
  isTrend,
}: StatusChartPropsType) {
  const { t } = useTranslation(['status']);

  return (
    <StatusWaterContainer>
      <h3>{title}</h3>
      <DoughnutChart
        chartData={chartData}
        tooltipList={tooltipList}
        isTrend={isTrend}
      />
    </StatusWaterContainer>
  );
}

export default StatusChart;

const StatusWaterContainer = styled.div`
  background: #fff;
  padding: 16px 24px;

  text-align: center;

  h3 {
    font-size: 16px;
    font-weight: 500;
  }
`;
