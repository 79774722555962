import React, { useEffect } from 'react';
import styled, { CSSProperties } from 'styled-components';
import AssetStatus from './AssetStatus';
import GoTop from '../_common/GoTop';
import useTrendSearch from './hooks/useTrendSearch';
import { Controller, useWatch } from 'react-hook-form';
import { MultiSelect } from 'react-multi-select-component';
import '../../styles/rmsc.css';
import SelectDate from '../_common/SelectDate';
import useSelectDate from '../_common/SelectDate/hooks/useSelectDate';
import { ClipLoader } from 'react-spinners';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const override: CSSProperties = {
  margin: '0 6px -6px 0',
};

const Trend = () => {
  const isTrend = true;
  const {
    startTime,
    endTime,
    startOpen,
    endOpen,
    startRef,
    endRef,
    handleTimeChange,
    handleTimeClick,
  } = useSelectDate();
  const {
    hookForm,
    selectAssetInitial,
    handleTrendClearForm,
    handleTrendSearch,
    excelDownloadMutation,
    handleExcelDownload,
    getTrendQuery,
    trendAssets,
    trendScrollRef,
  } = useTrendSearch();

  const [searchParams] = useSearchParams();
  const { control, handleSubmit } = hookForm;
  const assetCodes = useWatch({
    control,
    name: 'assetCodes',
  });
  const { t } = useTranslation(['trend']);

  useEffect(() => {
    if (_.isEmpty(Array.from(searchParams))) {
      handleTrendClearForm();
    }
  }, [handleTrendClearForm, searchParams]);

  return (
    <TrendWrapper>
      <TrendSearchContainer>
        <TrendSearchTitle>{t('TrendSearch.title')}</TrendSearchTitle>
        <TrendSearchWrapper>
          <form onSubmit={handleSubmit(handleTrendSearch)}>
            <div>
              <span className="title">{t('TrendSearch.name')}</span>
              <Controller
                control={control}
                name="assetCodes"
                render={({ field }) => {
                  return (
                    <StyledSelect
                      options={trendAssets}
                      value={assetCodes || []}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      labelledBy="Select"
                      overrideStrings={selectAssetInitial}
                      hasSelectAll={false}
                    />
                  );
                }}
              />
            </div>

            <SelectDate
              hookForm={hookForm}
              isTrend={isTrend}
              startTime={startTime}
              endTime={endTime}
              startOpen={startOpen}
              endOpen={endOpen}
              startRef={startRef}
              endRef={endRef}
              handleTimeChange={handleTimeChange}
              handleTimeClick={handleTimeClick}
            />

            <SearchButtonWrapper>
              <button
                className="reset"
                type="button"
                onClick={handleTrendClearForm}
              >
                {t('TrendSearch.reset')}
              </button>
              <button className="search" type="submit">
                {t('TrendSearch.search')}
              </button>
              <button
                className="download"
                type="button"
                onClick={() =>
                  handleExcelDownload(new Date().getTimezoneOffset())
                }
              >
                {excelDownloadMutation.isLoading ? (
                  <ClipLoader
                    color="var(--main-color)"
                    loading={excelDownloadMutation.isLoading}
                    cssOverride={override}
                    size={13}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                ) : (
                  <span>{t('TrendSearch.download')}</span>
                )}
              </button>
            </SearchButtonWrapper>
          </form>
        </TrendSearchWrapper>
      </TrendSearchContainer>

      <TrendList ref={trendScrollRef}>
        {getTrendQuery.data &&
          trendAssets &&
          getTrendQuery.data.content.map((asset, index) => {
            return trendAssets
              .map((data) => data.value)
              .includes(asset.assetCode) ? (
              <AssetStatus
                key={asset.assetCode}
                trendAsset={asset}
                isTrend={true}
              />
            ) : (
              ''
            );
          })}
        <GoTop trendScrollRef={trendScrollRef} />
      </TrendList>
    </TrendWrapper>
  );
};

const TrendWrapper = styled.div`
  font: normal 16px var(--main-font);
  width: 100%;
  height: calc(100vh - 60px);
  padding: 15px;
  box-sizing: border-box;
  margin-top: 60px;
`;

const TrendSearchContainer = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 16px 24px;
  box-sizing: border-box;
  margin-bottom: 16px;
`;

const TrendSearchTitle = styled.div`
  font-weight: 500;
  margin-bottom: 12px;
`;

const TrendSearchWrapper = styled.div`
  font-size: 14px;

  form {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;

    .title {
      display: inline-block;
      min-width: 60px;
    }

    input {
      width: 174px;
      height: 20px;
      border-radius: 5px;
      font-size: 14px;

      &:focus {
        outline-color: var(--main-color);
      }
    }
  }
`;

const StyledSelect = styled(MultiSelect)`
  display: inline-block;
  width: 200px;
  margin: 12px;

  .options {
    max-height: 400px;
  }

  .select-item {
    padding: 8px 10px;
  }

  .item-renderer {
    align-items: center;

    input {
      width: 20px;
      border: 1px solid red;
      margin-right: 8px;
    }

    input[type='checkbox'] {
      position: relative;
      cursor: pointer;
    }
    input[type='checkbox']:before {
      content: '';
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      top: 0;
      left: 0;
    }
    input[type='checkbox']:checked:before {
      content: '';
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      top: 0;
      left: 0;
      background-color: var(--main-color);
      border-radius: 3px;
    }
    input[type='checkbox']:checked:after {
      content: '';
      display: block;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      top: 2px;
      left: 6px;
    }
  }
`;

const SearchButtonWrapper = styled.div`
  padding: 12px 0;
  button {
    padding: 8px 12px;
    margin-right: 8px;
    border-radius: 5px;
    font-weight: 500;
  }

  .reset {
    color: var(--main-color);
    border: 1px solid var(--main-color);
  }

  .search {
    color: #fff;
    border: 1px solid var(--main-color);
    background-color: var(--main-color);
  }

  .download {
    width: 95px;
    height: 37px;
    white-space: nowrap;
    color: var(--main-color);
    border: 1px solid var(--main-color);
  }
`;

const TrendList = styled.div`
  box-sizing: border-box;
  height: calc(100% - 140px);

  overflow: auto;
`;

export default Trend;
