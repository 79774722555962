import React, { useCallback } from 'react';
import { Slide, toast } from 'react-toastify';
import Alarm from 'src/components/_common/Alarm';
import useManholeStore, { AssetType } from 'src/store/ManholeStore';

function useToastAlarm() {
  const { setSelectedAsset } = useManholeStore();

  /**
   * handle click Toast
   */
  const handleClickToast = useCallback(
    (asset: AssetType) => {
      setSelectedAsset(asset);
    },
    [setSelectedAsset],
  );

  /**
   * open toast alarm
   */
  const openToastAlarm = useCallback(
    (asset: AssetType) => {
      if (asset.status !== 'NORMAL') {
        toast(<Alarm asset={asset} width={'100%'} isToast={true} />, {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          transition: Slide,
          onClick: () => handleClickToast(asset),
        });
      }
    },
    [handleClickToast],
  );

  return { openToastAlarm };
}

export default useToastAlarm;
