import React, { useCallback } from 'react';
import _ from 'lodash';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  GetProjectApiResponseType,
  getProjectsApi,
} from 'src/api/oAuth/oAuthApi';

import {
  getProjectFromCookie,
  removeCookie,
  SERVICE_PATH,
  setCookie,
} from 'src/utils/cookies';
import { useLocation, useNavigate } from 'react-router-dom';
import useManholeStore from 'src/store/ManholeStore';
import useAuthStore from 'src/store/AuthStore';

const useProjectTab = () => {
  // projectCode dispatch
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();
  const { setResetManholeStore } = useManholeStore();
  const { loggedIn, setProjectArray } = useAuthStore();

  const serviceCode = 'XHW8-23QF-MZAD';

  const getProjectsQuery = useQuery<GetProjectApiResponseType>(
    ['projects'],
    () => getProjectsApi({ serviceCode }),
    {
      onSuccess: (res) => {
        const cookieProjectCode = getProjectFromCookie();
        if (!_.isEmpty(res.projects)) {
          const selectedProject = cookieProjectCode
            ? res.projects.find((project) => project.code === cookieProjectCode)
            : res.projects[0];

          if (selectedProject) {
            const servicePath = `/services/${selectedProject.serviceCode}/projects/${selectedProject.code}`;

            setCookie(SERVICE_PATH, servicePath, {});
            setProjectArray(selectedProject);
          }
        }
      },
      enabled: loggedIn,
      refetchOnWindowFocus: false,
    },
  );

  // projectCode에 맞는 데이터 호출
  const handleClickSetProject = useCallback(
    (res) => {
      removeCookie(SERVICE_PATH);
      setProjectArray(res);

      setCookie(
        SERVICE_PATH,
        `/services/XHW8-23QF-MZAD/projects/${res.code}`,
        {},
      );
      setResetManholeStore();

      queryClient.resetQueries(['assets']);
      queryClient.invalidateQueries(['assets-status']);
      queryClient.invalidateQueries(['history']);
      queryClient.invalidateQueries(['alarm']);
      queryClient.invalidateQueries(['trend']);
      queryClient.invalidateQueries(['trend-asset']);
      navigate(location.pathname);
    },

    [
      location.pathname,
      navigate,
      queryClient,
      setProjectArray,
      setResetManholeStore,
    ],
  );
  return {
    handleClickSetProject,
    getProjectsQuery,
  };
};

export default useProjectTab;
