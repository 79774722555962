import React from 'react';
import { useTranslation } from 'react-i18next';
import useSensor from 'src/hooks/useSensor';
import { StatusType } from 'src/types/shadow';
import styled from 'styled-components';
import StatusLabel from '../StatusLabel';

export interface AlarmBodyPropsType {
  status: StatusType;
  sensorName: string;
  sensorValue: any;
}

function AlarmBody({ status, sensorName, sensorValue }: AlarmBodyPropsType) {
  const { getSensorQuery } = useSensor();
  const { t } = useTranslation(['common']);
  const sensorValueText =
    typeof sensorValue === 'boolean'
      ? sensorValue
        ? `${t('OpenStatus.open')}`
        : `${t('OpenStatus.close')}`
      : sensorValue;

  return (
    <AlarmBodyWrapper>
      <StatusLabel status={status} />
      <AlarmData>
        <div className="alarm-sensor">{t(`Sensor.${sensorName}`)}</div>

        {status === 'ERROR' ? (
          <div>N/A</div>
        ) : (
          <div className="alarm-value">
            {sensorValueText} {getSensorQuery.data?.sensor[sensorName]?.unit}
          </div>
        )}
      </AlarmData>
    </AlarmBodyWrapper>
  );
}

export default React.memo(AlarmBody);

const AlarmBodyWrapper = styled.div`
  box-sizing: border-box;
  padding-bottom: 16px;
  display: flex;

  gap: 15px;
  align-items: center;
  font-size: 14px;
  white-space: nowrap;
`;

const AlarmData = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .alarm-value {
    font-weight: 500;
    span {
      font-weight: normal;
    }
  }
`;
