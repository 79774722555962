import { AssetListType } from 'src/store/ManholeStore';
import { DefaultPaginationType } from 'src/types/pagination';
import { getCookie } from 'src/utils/cookies';
import { ApiPathPrefix } from '../apiPathPrefix';
import { afterAxios, fetchApi } from '../config';

export type GetAlarmApiResponseType = DefaultPaginationType<AssetListType>;

export const getAlarmApi = async (res: { page: number; size: number }) => {
  return await fetchApi({
    url: `${getCookie('MH_PATH')}${ApiPathPrefix.ALARM}`,
    method: 'GET',
    config: {
      params: {
        ...res,
      },
    },
  }).then(afterAxios);
};

/**
 * Alarm 삭제 Api
 */
interface DeleteAlarmApiRequestType {
  alarmCode: string[];
  isAll?: boolean;
}

export const deleteAlarmApi = (req: DeleteAlarmApiRequestType) => {
  return fetchApi({
    url: `${getCookie('MH_PATH')}${ApiPathPrefix.ALARM}`,
    method: 'DELETE',
    config: {
      params: {
        alarmCode: req.alarmCode.join(','),
      },
    },
  }).then(afterAxios);
};
