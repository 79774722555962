import React, { useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import { subDays } from 'date-fns';

function useSelectDate() {
  /**
   * 시작일, 종료일 선택
   */

  const [startTime, setStartTime] = useState<Date>(subDays(new Date(), 3));
  const [endTime, setEndTime] = useState<Date>(new Date());
  const [startOpen, setStartOpen] = useState(false);
  const [endOpen, setEndOpen] = useState(false);

  const startRef = useRef<HTMLDivElement>(null);
  const endRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(startRef, () => setStartOpen(false));
  useOnClickOutside(endRef, () => setEndOpen(false));

  const handleTimeChange = (e, target) => {
    target === 'start' ? setStartTime(e) : setEndTime(e);
  };

  const handleTimeClick = (e, target) => {
    e.preventDefault();
    target === 'start' ? setStartOpen(!startOpen) : setEndOpen(!endOpen);
  };

  return {
    startTime,
    endTime,
    startOpen,
    endOpen,
    startRef,
    endRef,
    handleTimeChange,
    handleTimeClick,
  };
}

export default useSelectDate;
