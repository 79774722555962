import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  Filler,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ThresholdType } from 'src/components/Trend/types';
import useSensor from 'src/hooks/useSensor';
import _ from 'lodash';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  annotationPlugin,
  ChartDataLabels,
);

interface LineChartType {
  chartData: any;
  sensorName?: string;
}

function LineChart({ chartData, sensorName }: LineChartType) {
  const { getSensorQuery } = useSensor();
  const sensorInfo: ThresholdType =
    getSensorQuery.data && sensorName && getSensorQuery.data.sensor[sensorName];

  const { t } = useTranslation(['common']);

  /**
   * 임계치 위험 값
   */

  const annotationArr = !_.isEmpty(sensorInfo?.thresholds)
    ? sensorInfo.thresholds?.map((v, i) => {
        return {
          type: 'line',
          borderColor: '#ff6a80',
          borderDash: [2, 2],
          yMin: v - 0.02,
          yMax: v - 0.02,
          borderWidth: 1,
          label: {
            display: false,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            drawTime: 'afterDatasetsDraw',
            content: sensorName !== 'open' && `${v} ${sensorInfo?.unit}`,
          },
          enter({ element }, event) {
            element.label.options.display = true;
            return true;
          },
          leave({ element }, event) {
            element.label.options.display = false;
            return true;
          },
        };
      })
    : [];

  const annotation = {
    type: 'line',
    borderColor: '#ff6a80',
    borderDash: [2, 2],
    yMin: sensorInfo?.threshold && sensorInfo.threshold - 0.02,
    yMax: sensorInfo?.threshold && sensorInfo.threshold - 0.02,
    borderWidth: 2,
    label: {
      display: false,
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      drawTime: 'afterDatasetsDraw',
      content:
        sensorName !== 'open' && `${sensorInfo?.threshold} ${sensorInfo?.unit}`,
    },
    enter({ element }, event) {
      element.label.options.display = true;
      return true;
    },
    leave({ element }, event) {
      element.label.options.display = false;
      return true;
    },
  };

  const annotationObj =
    annotationArr && !_.isEmpty(annotationArr)
      ? annotationArr.reduce((acc, currentValue, index) => {
          acc[`annotation_${index + 1}`] = currentValue;
          return acc;
        }, {})
      : {};

  const options = {
    interaction: {
      intersect: false,
      mode: 'index',
    },
    maintainAspectRatio: false,

    plugins: {
      legend: false,
      tooltip: {
        callbacks: {
          label: function (tooltipItem, d) {
            const value = tooltipItem.formattedValue;
            const unit = sensorInfo?.unit;
            return sensorName === 'open'
              ? Number(value) === 0
                ? ` ${t('OpenStatus.close')}`
                : ` ${t('OpenStatus.open')}`
              : ` ${value} ${unit}`;
          },
        },
      },
      annotation: {
        common: {
          drawTime: 'beforeDatasetsDraw',
        },
        annotations: !_.isEmpty(sensorInfo.thresholds)
          ? { ...annotationObj }
          : sensorInfo.threshold
          ? {
              annotation,
              // normalBox: {
              //   type: 'box',
              //   borderWidth: 0,
              //   yMin: -10,
              //   yMax: -5,
              //   backgroundColor: 'rgba(33, 213, 155, 0.2)',
              //   drawTime: 'beforeDraw',
              // },
              // warningBox: {
              //   type: 'box',
              //   borderWidth: 0,
              //   yMin: -5,
              //   yMax: 1,
              //   backgroundColor: 'rgba(255, 196, 0, 0.2)',
              //   drawTime: 'beforeDraw',
              // },
              // dangerBox: {
              //   type: 'box',
              //   borderWidth: 0,
              //   yMin: 1,
              //   yMax: 5,
              //   backgroundColor: 'rgba(255, 107, 129, 0.2)',
              //   drawTime: 'beforeDraw',
              // },
            }
          : {},
      },

      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
        },
        // ticks: { maxTicksLimit: 10 },
      },
      y: {
        // 공통
        min: sensorInfo?.bottom,
        max: sensorInfo?.top,

        // y축 reverse
        reverse: sensorInfo?.bottom > sensorInfo?.top && true,

        // 센서 조건에 따라 커스텀
        grid: sensorName === 'open' ? { display: false } : {},
        ticks:
          sensorName === 'open'
            ? {
                stepSize: 1,
                callback: (value) =>
                  value === 0
                    ? `${t('OpenStatus.close')}`
                    : `${t('OpenStatus.open')}`,
              }
            : {
                stepSize: sensorName === 'water_level' && 1,
                callback: (value) => `${value} ${sensorInfo?.unit || ''}`,
              },
      },
    },
  };

  return (
    <ChartContainer>
      <h1>{t(`Sensor.${sensorName}`)}</h1>
      {chartData ? (
        <Line
          data={chartData}
          options={options as unknown as ChartOptions<'line'>}
        />
      ) : (
        <div>
          <div>측정된 값이 없습니다.</div>
        </div>
      )}
    </ChartContainer>
  );
}

export default React.memo(LineChart);

const ChartContainer = styled.div`
  padding: 15px 0;
  height: 90%;
  width: 90%;
  margin: -12px auto 0 auto;

  h1 {
    text-align: center;
  }

  div {
    text-align: center;
    margin-top: 100px;
  }
`;
