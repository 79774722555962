import React, { useCallback, useEffect, useRef } from 'react';
import { Marker, Popup } from 'react-leaflet';
import styled from 'styled-components';
import L, { LatLngExpression } from 'leaflet';
import useCustomMarker from './hooks/useCustomMarker';
import SelectedAssetInfo from '../../../SelectedAssetInfo';
import { useNavigate } from 'react-router-dom';
import { subDays } from 'date-fns';
import useManholeStore, { AssetType } from 'src/store/ManholeStore';
import { useTranslation } from 'react-i18next';

interface CustomMarkerPropsType {
  asset: AssetType;
  map: L.Map;
  isActive?: boolean;
}

function CustomMarker({
  asset,
  isActive,
  map,
  ...props
}: CustomMarkerPropsType) {
  const markerRef = useRef<L.Marker>();
  const { setSelectedAsset } = useManholeStore();
  const navigate = useNavigate();
  const { t } = useTranslation(['home']);

  const { getAssetInfoQuery } = useCustomMarker({
    isActive,
  });

  // Marker Open
  useEffect(() => {
    if (markerRef.current) {
      if (isActive) {
        (markerRef.current as any).openPopup();
      } else {
        markerRef.current?.closePopup();
      }
    }
  }, [isActive]);

  /**
   * close popup handler
   */
  const handleClosePopup = useCallback(() => {
    map.closePopup();
  }, [map]);

  const icon = L.icon({
    iconUrl: `${process.env.PUBLIC_URL}/img/icon/${
      asset.active ? asset.status.toLowerCase() : 'inactive'
    }-marker.svg`,
    // iconUrl: `/img/icon/inactive-marker.svg`,
    iconSize: isActive ? [39, 66] : [27, 45],
    className: 'leaflet-div-icon',
  });

  return (
    <Marker
      ref={markerRef as any}
      position={asset.geoLocation?.gps as LatLngExpression}
      icon={icon}
      zIndexOffset={isActive ? 1000 : 600}
      eventHandlers={{
        click: (e) => {
          setSelectedAsset(asset);

          if (asset?.geoLocation?.gps) {
            const [lng, lat] = asset?.geoLocation?.gps;
            const zoomScale = map.getZoom();

            map
              .setView({ lng, lat })
              .fitBounds([[lng, lat]])
              .setZoom(zoomScale, {
                animate: false,
              });
          }
        },
      }}
    >
      <StyledPopup closeButton={false} {...props}>
        <CustomPopupContainer>
          <CustomPopupHeader>
            <div>
              <h3>{asset.assetName}</h3>
              <span
                onClick={() => {
                  navigate(
                    `/trend?assetCodes=${asset.assetCode}&startDate=${subDays(
                      new Date(),
                      3,
                    ).toISOString()}&endDate=${new Date().toISOString()}`,
                  );
                }}
              >
                {t('Marker.detail')}
              </span>
            </div>
            <button onClick={handleClosePopup}>
              <img
                src={`${process.env.PUBLIC_URL}/img/icon/close.svg`}
                alt=""
                width={15}
                height={15}
              />
            </button>
          </CustomPopupHeader>
          <CustomPopupContentBox>
            {getAssetInfoQuery.data && (
              <SelectedAssetInfo asset={getAssetInfoQuery.data} />
            )}
          </CustomPopupContentBox>
        </CustomPopupContainer>
      </StyledPopup>
    </Marker>
  );
}

export default CustomMarker;

const StyledPopup = styled(Popup)`
  .leaflet-popup-content {
    width: 100% !important;
    padding: 0;
    margin: 0;
  }

  margin-bottom: 60px;
`;

const CustomPopupContainer = styled.div`
  width: 312px;
  padding: 16px;
`;

const CustomPopupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--border-color);

  div {
    display: flex;
    align-items: center;

    h3 {
      font: var(--subtitle-font);
    }
    span {
      color: var(--badge-text-color);
      background-color: #aaadad;
      border-radius: 50px;
      line-height: 22px;
      padding: 2px 10px 0 10px;
      margin-left: 12px;
      cursor: pointer;
    }
  }
  & > button {
    color: #212529;
    &:hover {
      color: #000;
    }
  }
`;

const CustomPopupContentBox = styled.div`
  width: 100%;
  box-sizing: border-box;

  div {
    &:last-child {
      padding-bottom: 0;
    }
  }
`;
