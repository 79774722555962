import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IoIosArrowUp } from 'react-icons/io';

interface GoTopPropsType {
  trendScrollRef: React.RefObject<HTMLDivElement>;
}

function GoTop({ trendScrollRef }: GoTopPropsType) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const trendScrollDiv = trendScrollRef.current;

  const updateScroll = () => {
    setScrollPosition(trendScrollDiv?.scrollTop || 0);
  };

  useEffect(() => {
    trendScrollDiv?.addEventListener('scroll', updateScroll);
    return () => {
      trendScrollDiv?.removeEventListener('scroll', updateScroll);
    };
  });

  const handleScrollTop = () => {
    trendScrollDiv?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <GoTopContainer onClick={() => handleScrollTop()}>
      <div className={`go-top-wrapper ${scrollPosition > 200 ? 'go-top' : ''}`}>
        <IoIosArrowUp color="#fff" />
      </div>
    </GoTopContainer>
  );
}

export default GoTop;

const GoTopContainer = styled.div`
  .go-top-wrapper {
    display: none;
  }
  .go-top {
    display: block;
    position: fixed;
    right: 50px;
    bottom: 50px;
    z-index: 100;
    transition: all 0.3s;
    cursor: pointer;
    background-color: #888;
    width: 48px;
    height: 48px;
    border-radius: 50px;
    text-align: center;
    line-height: 48px;
    font-size: 20px;

    &:hover {
      background-color: var(--main-color);
    }
  }
`;
