import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  GetAssetsApiRequestType,
  getAssetApi,
  patchCloseApi,
  PatchCloseRequestType,
} from 'src/api/asset/assetApi';
import { getCookie, SERVICE_PATH } from 'src/utils/cookies';
import { toast } from 'react-toastify';
import useManholeStore from 'src/store/ManholeStore';
import useAuthStore from 'src/store/AuthStore';
import { useTranslation } from 'react-i18next';

function useAssetsList() {
  const {
    assets,
    statusAssets,
    setStatusAssets,
    selectedAsset,
    setSelectedAsset,
    initTime,
    setInitTime,
    setUpdateTime,
    selectedListTab,
    activeTab,
  } = useManholeStore();
  const { loggedIn } = useAuthStore();
  const queryClient = useQueryClient();
  const { t } = useTranslation(['home']);

  /**
   * 상태 별 디바이스 목록
   */
  const statusParam = useMemo<GetAssetsApiRequestType>(() => {
    return { status: selectedListTab, isActive: activeTab };
  }, [activeTab, selectedListTab]);

  const getAssetsStatusQuery = useQuery(
    ['assets-status', statusParam],
    () => getAssetApi({ ...statusParam } as GetAssetsApiRequestType),
    {
      onSuccess: (res) => {
        const sortedEventByTimeStamp = _.sortBy(res.events, 'timestamp')
          .reverse()
          .filter((item) => item.timestamp && item.sensor && item.geoLocation);

        // 데이터 갱신 타임 갱신
        if (!_.isEmpty(sortedEventByTimeStamp) && !initTime) {
          setUpdateTime(sortedEventByTimeStamp[0].timestamp);
          setInitTime(true);
        }

        setStatusAssets(sortedEventByTimeStamp);

        // 선택 맨홀이 있으면 res 값으로 최신화
        if (selectedAsset) {
          setSelectedAsset(
            res.events.find(
              (item) => item.assetCode === selectedAsset.assetCode,
            ),
          );
        }
      },
      refetchOnWindowFocus: false,
      enabled: loggedIn && !!getCookie(SERVICE_PATH),
    },
  );

  const handleClickAsset = useCallback(
    (assetCode: string) => {
      if (!_.isEmpty(assets)) {
        const findAsset = assets.find((asset) => asset.assetCode === assetCode);

        if (findAsset) {
          setSelectedAsset(findAsset);
        }
      }
    },
    [assets, setSelectedAsset],
  );

  /**
   * 뚜껑 닫힘 처리 mutation
   */
  const patchCloseMutation = useMutation(patchCloseApi, {
    onMutate: (req: PatchCloseRequestType) => () => patchCloseApi(req),
    onSuccess: () => {
      queryClient.invalidateQueries(['assets']);
      queryClient.invalidateQueries(['assets-status']);
      toast.success(`${t('SelectedSensor.success_close')}`);
    },
  });

  const handleClickClose = useCallback(
    (data) => {
      patchCloseMutation.mutate({
        assetCode: data,
      });
    },
    [patchCloseMutation],
  );

  return {
    handleClickAsset,
    getAssetsStatusQuery,
    handleClickClose,
    assets,
    statusAssets,
    selectedAsset,
  };
}

export default useAssetsList;
