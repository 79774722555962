import React from 'react';
import styled, { css } from 'styled-components';
import AlarmBody from './AlarmBody';
import { AlarmContentType } from './types';
import { addSeconds, format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import useManholeStore from 'src/store/ManholeStore';
import { useQueryClient } from '@tanstack/react-query';

interface AlarmPropsType {
  onClick?: () => void;
  asset: AlarmContentType;
  width?: string;
  handleDeleteAlarm?: (id?) => void;
  isToast: boolean;
  setOpenAlarm?: (data: boolean) => void;
}

function Alarm({
  onClick,
  asset,
  width = '312px',
  handleDeleteAlarm,
  isToast,
  setOpenAlarm,
}: AlarmPropsType) {
  delete asset.sensor.imu;
  const navigate = useNavigate();
  const { init, setInit } = useManholeStore();
  const queryClient = useQueryClient();

  return (
    <AlarmContainer
      className="alarm-container"
      onClick={() => {
        navigate(
          `/history?assetName=${asset.assetName}&assetCode=${
            asset.assetCode
          }&endDate=${addSeconds(
            new Date(asset.timestamp),
            1,
          ).toISOString()}&isAlarm=true`,
        );
        queryClient.invalidateQueries(['history']);

        if (init) setInit(false);

        setOpenAlarm && setOpenAlarm(false);
        if (onClick) onClick();
      }}
      width={width}
      isToast={isToast}
    >
      <div className="alarm-header">
        <div>
          <p>{asset.assetName}</p>
          <p className="alarm-date">
            {format(asset.timestamp, 'yyyy.MM.dd HH:mm:ss')}
          </p>
        </div>

        {isToast || (
          <button
            className="alarm-close"
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteAlarm && handleDeleteAlarm(asset.id);
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/img/icon/close.svg`}
              alt=""
              width={15}
              height={15}
            />
          </button>
        )}
      </div>
      <div className="alarm-main">
        {Object.keys(asset.sensor).map((item) => {
          return (
            <AlarmBody
              key={item}
              status={asset.sensor[item].status.toUpperCase()}
              sensorName={item}
              sensorValue={asset.sensor[item].value}
            />
          );
        })}
      </div>
    </AlarmContainer>
  );
}

export default React.memo(Alarm);

const AlarmContainer = styled.div<{ width: string; isToast: boolean }>`
  width: ${({ width }) => width || '100%'};
  height: fit-content;
  background-color: #fff;
  box-sizing: border-box;
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;

  ${({ isToast }) =>
    !isToast &&
    css`
      &:hover {
        background-color: #f2f2f2;
      }
    `}

  & > .alarm-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font: bold var(--font-size-14) var(--main-font);
    padding-bottom: 16px;
    margin-bottom: 16px;

    .alarm-date {
      font-size: 12px;
      margin-top: 2px;
      color: var(--manhole-name-font-color);
    }

    .alarm-close {
      color: #212529;

      &:hover {
        color: #000;
      }
    }
  }

  & .alarm-main > div {
    &:last-child {
      padding-bottom: 0;
    }
  }
`;
