export const DEVICE_STATUS_TAB_LIST = [
  {
    id: 'ALL',
    status: '',
    color: '#000',
  },
  {
    id: 'DANGER',
    status: 'DANGER',
    color: 'var(--chart-danger)',
  },
  {
    id: 'WARNING',
    status: 'WARNING',
    color: 'var(--chart-warning)',
  },
  {
    id: 'NORMAL',
    status: 'NORMAL',
    color: 'var(--chart-normal)',
  },
  {
    id: 'INACTIVE',
    color: '#aaadad',
    isActive: false,
  },
];
