import React from 'react';
import usePagination from 'src/hooks/usePagination';
import styled from 'styled-components';
import HistoryList from './HistoryList';
import HistorySearch from './HistorySearch';
import useHistorySearch from './hooks/useHistorySearch';

function History() {
  const searchPagination = usePagination();
  const {
    handleClickHistory,
    page,
    size,
    getHistoryQuery,
    historyId,
    statusOptions,
    hookForm,
    handleClearForm,
    handleSubmitSearch,
    handleClickPagination,
  } = useHistorySearch({ searchPagination });
  const isTrend = false;

  return (
    <HistoryContainer>
      <HistorySearch
        statusOptions={statusOptions}
        hookForm={hookForm}
        handleClearForm={handleClearForm}
        handleSubmitSearch={handleSubmitSearch}
        isTrend={isTrend}
      />
      <HistoryList
        handleClickHistory={handleClickHistory}
        page={page}
        size={size}
        handlePageChange={handleClickPagination}
        getHistoryQuery={getHistoryQuery}
        historyId={historyId}
      />
    </HistoryContainer>
  );
}

export default History;

const HistoryContainer = styled.div`
  font: normal 16px var(--main-font);
  padding: 16px;
  margin-top: 60px;
`;
