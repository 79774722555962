import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';

import {
  removeCookie,
  ID_TOKEN_NAME,
  ACCESS_TOKEN_NAME,
  REFREASH_TOKEN_NAME,
  CUSTOMER_CODE_NAME,
  SERVICE_PATH,
  setCookie,
} from 'src/utils/cookies';
import { setToken, decodeToken } from 'src/utils/setToken';
import { useNavigate } from 'react-router-dom';
import { signInApi, SignInRequestType } from 'src/api/oAuth/oAuthApi';
import useManholeStore from 'src/store/ManholeStore';
import useAuthStore from 'src/store/AuthStore';
import { useTranslation } from 'react-i18next';

export default function useLogin() {
  const hookForm = useForm({
    defaultValues: {
      customerCode: window._env_.REACT_APP_CUSTOMER_CODE,
      username: '',
      password: '',
    },
  });
  const navigate = useNavigate();
  const { setSignOut } = useAuthStore();
  const { setResetManholeStore } = useManholeStore();
  const { t } = useTranslation(['login']);

  /**
   * Login Mutation
   */
  const loginMutation = useMutation(signInApi, {
    onMutate: (req: SignInRequestType) => () => signInApi(req),
  });

  /**
   * handle Login
   */
  const handleLogin = useCallback(
    async (data) => {
      const res = await loginMutation.mutateAsync(data);

      if (res.status === 200) {
        await setToken(res.data);
        decodeToken(res.data.idToken);

        const customerCode = hookForm.getValues('customerCode');

        setCookie(CUSTOMER_CODE_NAME, customerCode, {});

        navigate('/');
      } else {
        alert(`${t('login_error')}`);
      }
    },
    [hookForm, loginMutation, navigate, t],
  );

  /**
   * handle Logout
   */
  const handleLogout = useCallback(async () => {
    removeCookie(ID_TOKEN_NAME);
    removeCookie(ACCESS_TOKEN_NAME);
    removeCookie(REFREASH_TOKEN_NAME);
    removeCookie(CUSTOMER_CODE_NAME);
    removeCookie(SERVICE_PATH);

    setSignOut();
    setResetManholeStore();
    // delete defaultInstance.defaults.headers.Authorization;

    navigate('/login');
  }, [navigate, setResetManholeStore, setSignOut]);

  return { hookForm, handleLogin, handleLogout };
}
