import React from 'react';
import useLogin from './hooks/useLogin';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export default function Login() {
  const { t } = useTranslation(['login']);
  const { handleLogin, hookForm } = useLogin();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = hookForm;

  const isWizcore = window.location.href.includes('wizcore');
  return (
    <LoginContainer>
      <div className="loginContent">
        <div className="divForLoginContentAlign">
          <div className="loginContentWrap">
            <img
              src={`${
                !isWizcore
                  ? `${process.env.PUBLIC_URL}/img/login_manhole_img.png`
                  : `${process.env.PUBLIC_URL}/img/login_manhole_wizcore.png`
              }`}
              alt=""
              className="loginImage"
            />
            <div className="loginBox">
              <div className="loginText">{t('login_title')}</div>
              <div className="loginFormWrap">
                <form
                  className="loginForm"
                  onSubmit={handleSubmit(handleLogin)}
                >
                  <div className="inputWrap">
                    <input
                      {...register('customerCode')}
                      type="text"
                      className={`email login loginInput ${
                        errors.customerCode ? 'error' : ''
                      }`}
                      id="loginEmail"
                      value={window._env_.REACT_APP_CUSTOMER_CODE}
                      autoFocus
                    />
                  </div>
                  <div className="inputWrap">
                    <input
                      {...register('username', {
                        required: {
                          value: true,
                          message: '이메일을 입력해주세요.',
                        },
                      })}
                      type="email"
                      className={`email login loginInput ${
                        errors.username ? 'error' : ''
                      }`}
                      id="loginEmail"
                      placeholder="E-mail"
                      autoFocus
                    />
                  </div>
                  <div className="inputWrap">
                    <input
                      {...register('password', {
                        required: {
                          value: true,
                          message: '비밀번호를 입력해주세요.',
                        },
                      })}
                      type="password"
                      className={`password login loginInput ${
                        errors.password ? 'error' : ''
                      }`}
                      id="loginPassword"
                      placeholder="Password"
                    />
                  </div>
                  <div className="inputWrap">
                    <button
                      type="submit"
                      className="submit login"
                      id="loginButton"
                      value="LOGIN"
                    >
                      {t('login_button')}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoginContainer>
  );
}

const LoginContainer = styled.div`
  .loginContent {
    height: 100vh;
    display: flex;
    align-items: center;
    background-color: var(--text-born-color);
    overflow: auto;
  }

  .divForLoginContentAlign {
    width: 100%;
  }

  .loginContentWrap {
    width: 1100px;
    height: 645px;
    margin: auto;
    background-color: #ffffff;
    box-shadow: 0 3px 6px #00000029;
    opacity: 1;
  }

  .loginImage {
    float: left;
    box-shadow: 0px 3px 6px #00000029;
  }

  .loginBox {
    display: inline-block;
    width: 603px;
    height: 100%;
  }

  .loginText {
    margin: 108px 0 32px 0;
    font-size: 48px;
    font-family: 'Segoe UI Semibold', var(--main-font);
    font-weight: bold;
    text-align: center;
  }

  .loginFormWrap {
    text-align: center;
  }

  .login {
    width: 450px;
    height: 50px;
    border-radius: 32px;
    opacity: 1;
    border: 0;
  }

  .login:focus {
    outline: none;
  }

  .loginInput {
    background-color: #e7e7e7;
    font-family: 'Arial', var(--main-font);
    font-size: 16px;
    padding-left: 28px;
    box-sizing: border-box;
  }
  .loginInput.error {
    border: 1px solid red;
  }

  .loginInput::placeholder {
    color: #999999;
  }

  .email {
    margin-bottom: 20px;
  }

  .password {
    margin-bottom: 41px;
  }

  .submit {
    background-color: #a31f34;
    font-family: 'Arial', var(--main-font);
    font-size: 18px;
    color: #ffffff;
  }

  .login-error {
    color: red;
  }
`;
