import React from 'react';
import styled, { css } from 'styled-components';
import { format } from 'date-fns';
import { AssetType } from 'src/store/ManholeStore';
import useAssetSensor from './hooks/useAssetSensor';

interface AssetListPropsType {
  active?: boolean;
  timestamp: number;
  onClick?: (data: any) => void;
  assetName: string | undefined;
  asset: AssetType;
  activeStatus: boolean;
}
function AssetList({
  active,
  timestamp,
  onClick,
  assetName,
  asset,
  activeStatus,
}: AssetListPropsType) {
  const { sensorCount } = useAssetSensor({
    asset,
  });

  return (
    <AssetListContainer active={active} onClick={onClick}>
      <AssetDetail>
        <p>{assetName}</p>
        <p>{format(timestamp, 'yyyy.MM.dd HH:mm:ss')}</p>
      </AssetDetail>
      <StatusCountLabel>
        {sensorCount.DANGER && (
          <div className="danger">{sensorCount.DANGER}</div>
        )}
        {sensorCount.WARNING && (
          <div className="warning">{sensorCount.WARNING}</div>
        )}
      </StatusCountLabel>
      <ActiveStatus>{activeStatus || <div></div>}</ActiveStatus>
    </AssetListContainer>
  );
}

export default React.memo(AssetList);

const AssetListContainer = styled.div<{ active?: boolean }>`
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;

  cursor: pointer;
  border-bottom: 1px solid var(--border-color);

  ${({ active }) =>
    active &&
    css`
      background-color: #f2f2f2;
    `}

  &:hover {
    background-color: #f2f2f2;
  }
`;

const AssetDetail = styled.div`
  padding: 10px 20px;
  font: var(--font-size-14) var(--main-font);

  & p:first-child {
    font-weight: 500;
  }
`;

const StatusCountLabel = styled.div`
  display: flex;
  justify-content: baseline;
  align-items: center;

  div {
    width: 20px;
    height: 20px;
    font-size: 14px;
    text-align: center;
    border-radius: 5px;
    margin-left: 12px;
  }
  .danger {
    background-color: var(--danger-default-color);
  }
  .warning {
    background-color: var(--warning-default-color);
  }
`;

const ActiveStatus = styled.div`
  display: grid;
  align-items: center;
  border-left: 1px solid var(--border-color);
  box-sizing: border-box;

  div {
    width: 16px;
    height: 16px;
    background-color: #aaadad;
    border-radius: 50%;
    margin: auto;
  }
`;
