import React, { useCallback } from 'react';
import _ from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { SensorNameType } from 'src/types/shadow';
import { getSensorApi } from 'src/api/sensor/sensor';

function useSensor() {
  /**
   * 센서별 데이터 query
   */

  const getSensorQuery = useQuery(['sensor-data'], () => getSensorApi(), {
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  /**
   * 센서 우선순위
   */
  const orderBySensorPriority = useCallback((sensor: SensorNameType) => {
    if (_.isEmpty(sensor)) return [];
    return _(sensor)
      .keys()
      .filter(function (key) {
        return sensor[key].active === true;
      })
      .sortBy(function (key) {
        return sensor[key].priority;
      })
      .value();
  }, []);

  const sensorPriority = orderBySensorPriority(getSensorQuery.data?.sensor);

  /**
   * 센서 우선순위 적용 함수
   */
  // 우선순위 적용된 센서 리스트

  const sortedSensorPriority = useCallback(
    (sensorList: SensorNameType[] | undefined) => {
      const sortedSensorNameList: SensorNameType[] = [];
      sensorPriority.forEach(function (key) {
        let check = false;
        sensorList = sensorList?.filter(function (sensor) {
          if (!check && sensor === key) {
            sortedSensorNameList.push(sensor);
            check = true;
            return false;
          } else {
            return true;
          }
        });
      });

      return sortedSensorNameList;
    },
    [sensorPriority],
  );

  return {
    orderBySensorPriority,
    getSensorQuery,
    sensorPriority,
    sortedSensorPriority,
  };
}

export default useSensor;
