import React, { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import { AssetSensorType, SensorNameType } from 'src/types/shadow';
import { StatusCountType, TrendChartType } from '../types';
import { addHours, format } from 'date-fns';
import useSensor from 'src/hooks/useSensor';

interface UseAssetStatusPropsType {
  trendAsset: TrendChartType;
}

function useAssetStatus({ trendAsset }: UseAssetStatusPropsType) {
  const { sortedSensorPriority } = useSensor();
  const [showChart, setShowChart] = useState<boolean>(false);

  const getDataFromSensorName = useCallback((asset, sensorName) => {
    if (!asset.sensor) return [];
    return asset?.sensor.map((asset, i) => asset[sensorName]);
  }, []);

  /**
   * 센서별 누적 데이터
   * @return {
   *  name,
   *  normal,
   *  warning,
   *  danger,
   * }
   */
  const getCountStatus = useCallback(
    (asset, sensorName: SensorNameType): StatusCountType => {
      const sensorData: AssetSensorType[] = getDataFromSensorName(
        asset,
        sensorName,
      );

      return {
        name: sensorName,
        normal: sensorData.filter((data) => data?.status === 'NORMAL').length,
        warning: sensorData.filter((data) => data?.status === 'WARNING').length,
        danger: sensorData.filter((data) => data?.status === 'DANGER').length,
      };
    },
    [getDataFromSensorName],
  );

  /**
   * Asset sensor Name List
   * @return ['sensorName']
   */
  const sensorNameList = useCallback((data) => {
    if (data && !_.isEmpty(data.sensor)) {
      return _.uniq(
        data.sensor.reduce(
          (acc: SensorNameType[], cur) => [
            ...acc,
            ...(Object.keys(cur) as SensorNameType[]),
          ],
          [],
        ) as SensorNameType[],
      );
    }
    return [];
  }, []);

  /**
   * 우선순위 적용
   */
  const sortedSensorList = sortedSensorPriority(sensorNameList(trendAsset));

  /**
   * 센서별 누적 상태 개수 for doughnut chart
   */
  const sensorStatusCountArr =
    sortedSensorList &&
    sortedSensorList.map((sensorName, i) => {
      return Object.values(
        getCountStatus(trendAsset, sortedSensorList[i] as SensorNameType),
      );
    });

  const statusCountArr =
    !_.isEmpty(sensorStatusCountArr) &&
    sensorStatusCountArr.map((status, i) => {
      return status.filter((v, i) => i !== 0);
    });

  /**
   * 상태별 누적 개수 for doughnut chart
   */
  const totalCountArr = useMemo(
    () =>
      statusCountArr &&
      statusCountArr[0].map((_, index) =>
        statusCountArr.reduce((sum, current) => sum + current[index], 0),
      ),
    [statusCountArr],
  );

  const totalCount = totalCountArr && totalCountArr.push(0);

  const registerChartData = useCallback(
    (asset, sensorName: SensorNameType) => {
      const sensorData = getDataFromSensorName(asset, sensorName).filter(
        (item, i) => item !== undefined && item?.status !== 'ERROR',
      );

      if (!_.isEmpty(sensorData)) {
        return {
          labels: sensorData
            .map(
              (data) =>
                data &&
                format(
                  addHours(
                    new Date(data.timestamp),
                    window._env_.REACT_APP_ENVIRONMENT === 'ONPREMISE' ? 0 : 9,
                  ),
                  'MM.dd HH:mm:ss',
                ),
            )
            .reverse(),

          datasets: [
            {
              label: asset.assetCodes,
              borderColor: '#15b84b',
              borderWidth: 2,
              // fill: 'start',
              // backgroundColor: (context: ScriptableContext<'line'>) => {
              //   const ctx = context.chart.ctx;
              //   const gradient = ctx.createLinearGradient(0, 0, 0, 80);
              //   gradient.addColorStop(0, '#9dcfae3c');
              //   gradient.addColorStop(1, '#d0ce313b');
              //   return gradient;
              // },
              pointRadius: sensorData.length < 30 ? 3 : 1,
              data: sensorData
                .map((data, index) => {
                  return {
                    x: index,
                    y: data?.value,
                  };
                })
                .reverse(),
            },
          ],
        };
      }
    },
    [getDataFromSensorName],
  );

  return {
    getCountStatus,
    showChart,
    registerChartData,
    setShowChart,
    sortedSensorList,
    sensorStatusCountArr,
    totalCountArr,
  };
}

export default useAssetStatus;
