import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import Alarm from '../Alarm';
import styled, { css } from 'styled-components';
import NowDateText from './NowDateText';
import useLogin from '../../Login/hooks/useLogin';
import _ from 'lodash';
import useAlarm from 'src/components/_common/Alarm/hooks/useAlarm';
import { AlarmContentType } from '../Alarm/types';
import { IoIosArrowDown } from 'react-icons/io';
import { GoCheck } from 'react-icons/go';
import { useOnClickOutside } from 'usehooks-ts';
import useHeader from './hooks/useHeader';
import useProjectTab from './hooks/useProjectTab';
import useInitTime from 'src/components/_common/Header/hooks/useInitTime';

export default function Header() {
  const {
    getAlarmQuery,
    openAlarm,
    setOpenAlarm,
    alarmRef,
    handleAlarm,
    projects,
    alarmList,
    handleDeleteAlarm,
    handleAlarmDeleteAll,
    alarmTotal,
    alarmBodyRef,
    handleClickMore,
  } = useAlarm();
  const { handleClickHeader } = useHeader();
  const { handleClickSetProject, getProjectsQuery } = useProjectTab();
  const isWizcore = window.location.href.includes('wizcore');

  const tabRef = useRef<HTMLDivElement>(null);
  const [tabOpen, setTabOpen] = useState(false);
  const handleTab = () => {
    setTabOpen(!tabOpen);
  };
  useOnClickOutside(tabRef, () => {
    setTabOpen(false);
  });

  // 데이터 갱신
  useInitTime();

  const { t } = useTranslation(['header']);
  const location = useLocation();
  const { handleLogout } = useLogin();

  return (
    <HeaderContainer>
      <HeaderLeftDiv>
        <Link to={'/'} onClick={handleClickHeader}>
          <img
            alt="logo"
            src={`${
              !isWizcore
                ? `${process.env.PUBLIC_URL}/img/sdt-logo.png`
                : `${process.env.PUBLIC_URL}/img/logo_wizcore.png`
            }`}
            className="logo"
          />
        </Link>
        <p>{t('header_title')}</p>
      </HeaderLeftDiv>
      <HeaderCenterDiv>
        <div className="header-center-text-box">
          <LinkSpan
            isActive={location.pathname === '/'}
            onClick={handleClickHeader}
          >
            <Link to="/">{t('link_home')}</Link>
          </LinkSpan>
          <LinkSpan
            isActive={location.pathname === '/trend'}
            onClick={handleClickHeader}
          >
            <Link to="/trend">{t('link_trend')}</Link>
          </LinkSpan>
          <LinkSpan isActive={location.pathname === '/history'}>
            <Link to="/history">{t('link_history')}</Link>
          </LinkSpan>
        </div>
      </HeaderCenterDiv>

      <HeaderRightDiv>
        <HeaderTab ref={tabRef}>
          {getProjectsQuery.data?.projects.length === 1 ||
          getProjectsQuery.data?.projects === undefined ? (
            <div>
              <span> {projects?.name || ''} </span>
            </div>
          ) : (
            <>
              <div className="tabDefault" onClick={handleTab}>
                <span> {projects?.name} </span>
                <span>
                  <IoIosArrowDown size="18" />
                </span>
              </div>

              <ul className={`tabList ${tabOpen ? 'open' : ''}`}>
                {!_.isEmpty(getProjectsQuery.data?.projects) &&
                  getProjectsQuery.data?.projects.map((tab, i) => (
                    <li
                      key={i}
                      onClick={() => {
                        handleClickSetProject(tab);
                        handleTab();
                      }}
                    >
                      {tab.name}
                      {projects?.name === tab.name ? (
                        <GoCheck className="check" />
                      ) : (
                        ''
                      )}
                    </li>
                  ))}
              </ul>
            </>
          )}
        </HeaderTab>
        <HeaderData>
          {`${t('update_text')} : `}
          <NowDateText />
        </HeaderData>
        <HeaderAlarm ref={alarmRef}>
          <AlarmIcon onClick={handleAlarm}>
            <img
              src={`${process.env.PUBLIC_URL}/img/icon/ic-bell.svg`}
              width={28}
              height={28}
            />
            {getAlarmQuery.isSuccess &&
            !_.isEmpty(getAlarmQuery.data) &&
            alarmTotal ? (
              <div className="alarm-count">
                {alarmTotal > 99 ? `${99}+` : alarmTotal}
              </div>
            ) : (
              <div></div>
            )}
          </AlarmIcon>

          <AlarmBox>
            <div
              ref={alarmBodyRef}
              className={`alarmBox ${openAlarm ? 'open' : ''}`}
            >
              <AlarmListHeader>
                <p onClick={handleAlarmDeleteAll}>
                  {alarmTotal === 0 ? (
                    <div className="no-alarm">{t('no_alarm')}</div>
                  ) : (
                    <div className="alarm-read-all">{t('alarm_read_all')}</div>
                  )}
                </p>
              </AlarmListHeader>
              {!_.isEmpty(alarmList) &&
                alarmList.map((asset) => (
                  <Alarm
                    key={asset.id}
                    asset={asset as AlarmContentType}
                    handleDeleteAlarm={() => handleDeleteAlarm(asset.id)}
                    isToast={false}
                    setOpenAlarm={setOpenAlarm}
                  />
                ))}
              {alarmTotal !== alarmList.length && (
                <MoreButton onClick={handleClickMore}>more</MoreButton>
              )}
            </div>
          </AlarmBox>
        </HeaderAlarm>

        <HeaderProfile onClick={() => handleLogout()}>
          {!isWizcore ? 'sdt' : 'wiz'}
        </HeaderProfile>
      </HeaderRightDiv>
    </HeaderContainer>
  );
}

const LinkSpan = styled.span<{ isActive?: boolean }>`
  a:hover {
    font-weight: bold;
  }
  ${({ isActive }) =>
    isActive &&
    css`
      a {
        display: inline-block;
        font-weight: bold;
        border-bottom: 2px solid #fff;
        padding-bottom: 5px;
      }
    `}
`;

const HeaderContainer = styled.header`
  width: 100%;
  height: 60px;
  background-color: var(--main-color);
  font-family: var(--main-font);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 20px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  z-index: 10000;

  a {
    color: var(--text-born-color);
    outline: none;
    text-decoration: none;
  }
`;

const HeaderLeftDiv = styled.div`
  color: var(--text-born-color);
  font: normal normal normal 16px/24px var(--main-font);
  display: flex;
  align-items: center;

  & > a:nth-child(1) {
    margin-right: 13px;
  }
  & > p {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
  }

  img {
    width: 100%;
    height: 25px;
    margin-top: 4px;
  }
`;

const HeaderCenterDiv = styled.div`
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  font-size: 14px;
  padding: 10px 70px;
  color: var(--text-born-color);

  & a {
    margin: 0 20px;
  }
`;

const HeaderRightDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  color: var(--text-born-color);
`;

const HeaderTab = styled.div`
  font-size: 14px;
  position: relative;

  .tabDefault {
    margin-top: 4px;
    cursor: pointer;
    span {
      margin-right: 10px;
      line-height: 20px;

      &:last-child {
        vertical-align: middle;
      }
    }
  }

  .tabList {
    display: none;
    background-color: #fff;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    color: #000;
    width: 230px;
    border-radius: 5px;

    position: absolute;
    top: 44px;
    right: 0;
    z-index: 1000;

    li {
      display: flex;
      justify-content: space-between;
      padding: 10px 20px;
      cursor: pointer;
      line-height: 20px;

      &:hover {
        background-color: #f2f2f2;
      }

      &:first-child {
        padding-top: 20px;
      }
      &:last-child {
        padding-bottom: 20px;
      }
    }
  }

  .open {
    display: block;
  }

  .check {
    font-size: 20px;
  }
`;

const HeaderData = styled.div`
  font-size: 14px;
  margin-right: 10px;
`;

const HeaderAlarm = styled.div`
  position: relative;
  cursor: pointer;
  margin-top: 10px;
`;

const AlarmIcon = styled.div`
  & > img {
    width: 28px;
    height: 28px;
  }

  .alarm-count {
    position: absolute;
    background-color: #ff4646;
    color: #fff;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    text-align: center;
    padding: 2px;
    font-size: 0.7rem;
    top: 15%;
    right: 20%;
    transform: scale(1) translate(50%, -50%);
    transform-origin: 100% 0%;
    line-height: 16px;
  }
`;

const AlarmBox = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  color: #000;

  .alarmBox {
    display: none;
    top: 100px;
    right: 0;
    max-height: 700px;
    overflow-y: auto;
    z-index: 4;
    box-shadow: 2px 4px 8px 0 rgba(21, 34, 50, 0.16);
  }

  .open {
    display: block;
  }
`;

const HeaderProfile = styled.button`
  width: 32px;
  height: 32px;
  background: var(--text-born-color) 0% 0% no-repeat padding-box;
  margin-right: 28px;
  border-radius: 70%;
  font: normal normal normal 14px/21px Poppins;
  font-weight: 500;
  line-height: 1.5;
  color: var(--main-color);
  line-height: 32px;
  text-align: center;
`;

const MoreButton = styled.div`
  width: 100%;
  height: 48px;
  text-align: center;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 312px;
  cursor: pointer;
`;

const AlarmListHeader = styled.div`
  width: 100%;
  height: 100%;
  text-align: right;
  background-color: white;
  border-bottom: 1px solid var(--border-color);
  min-width: 312px;

  & > p {
    width: 100%;
    color: var(--main-color);
    font-size: 12px;
    font-weight: 400;
    display: inline-block;

    .no-alarm {
      font-size: 14px;
      color: #444;
      text-align: center;
      padding: 15px;
      cursor: auto;
    }

    .alarm-read-all {
      padding: 15px;
      cursor: pointer;
    }
  }
`;
