import { method } from 'lodash';
import { getCookie } from 'src/utils/cookies';
import { fetchApi, afterAxios } from '../config';

export const getImageApi = () =>
  fetchApi({
    method: 'GET',
    url: `${getCookie('MH_PATH')}/map`,
  }).then(afterAxios);

export const postImageApi = (file: File) => {
  const formData = new FormData();
  formData.append('images', file);
  return fetchApi({
    url: `${getCookie('MH_PATH')}/upload`,
    method: 'POST',
    contentType: 'multipart/form-data',
    data: formData,
  }).then(afterAxios);
};
