import _ from 'lodash';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export const REFREASH_TOKEN_NAME = 'MH_RT';
export const ACCESS_TOKEN_NAME = 'MH_AT';
export const ID_TOKEN_NAME = 'MH_IT';
export const CUSTOMER_CODE_NAME = 'MH_CC';
export const SERVICE_PATH = 'MH_PATH';

export const setCookie = (name, value, option) => {
  return cookies.set(name, value, { ...option });
};

export const getCookie = (name) => {
  return cookies.get(name);
};

export const removeCookie = (name) => {
  return cookies.remove(name);
};

/**
 * path에서 project 추출
 */
export const getProjectFromCookie = () => {
  const path = getCookie(SERVICE_PATH);

  return path ? _.last(path.split('/')) : '';
};
