import { getCookie } from 'src/utils/cookies';
import { ApiPathPrefix } from '../apiPathPrefix';
import { afterAxios, fetchApi } from '../config';

/**
 * 센서 정보
 */
export const getSensorApi = async () => {
  return await fetchApi({
    url: `${getCookie('MH_PATH')}${ApiPathPrefix.SENSOR}`,
    method: 'GET',
  }).then(afterAxios);
};
