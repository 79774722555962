import { SERVICE_PATH } from './../utils/cookies';
import axios, { AxiosRequestConfig, Method } from 'axios';
import {
  ACCESS_TOKEN_NAME,
  CUSTOMER_CODE_NAME,
  getCookie,
  ID_TOKEN_NAME,
  REFREASH_TOKEN_NAME,
  removeCookie,
} from '../utils/cookies';
import { setToken } from '../utils/setToken';
import { silentSignInApi } from './oAuth/oAuthApi';

export interface CallApiType {
  url: string;
  method: Method;
  data?: any;
  contentType?: HttpContentType;
  config?: AxiosRequestConfig;
  instanceType?: InstanceType;
  path?: string;
}

type InstanceType = 'Default' | 'Relay' | 'Refresh';

export type HttpContentType =
  | 'application/json'
  | 'application/x-www-form-urlencoded'
  | 'multipart/form-data'
  | 'form-data'
  | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8';

const createInstance = (config?: AxiosRequestConfig) => {
  const defaultTimeoutMillSec = 60 * 1000; // 60초
  return axios.create({
    baseURL: window._env_.REACT_APP_BASE_API_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    timeout: defaultTimeoutMillSec,
    ...config,
  });
};

export const defaultInstance = createInstance();

export const fetchApi = ({
  url,
  method,
  data,
  contentType = 'application/json',
  config,
  path,
  instanceType = 'Default',
}: CallApiType) => {
  /**
   * Api request interceptor
   */
  defaultInstance.interceptors.request.use(
    (req) => {
      const MH_IT = getCookie(ID_TOKEN_NAME);
      // console.log('#####', req);

      if (req.url?.includes('/token')) {
        delete req.headers?.Authorization;
      }

      if (MH_IT && !req.url?.includes('/token')) {
        req.headers = {
          ...req.headers,
          Authorization: `Bearer ${MH_IT}`,
        };
      }

      return req;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  /**
   * Api response interceptor
   */
  defaultInstance.interceptors.response.use(
    (req) => {
      // console.log('######## res interceptors', req);
      return req;
    },
    async (error) => {
      const {
        response: { status },
        config,
      } = error;

      if (status === 401) {
        const res = await silentSignInApi();

        const originalRequest = config;

        if (res.status === 200) {
          const { idToken } = res.data;
          setToken(res.data, true);

          defaultInstance.defaults.headers.common.Authorization = `Bearer ${idToken}`;
          originalRequest.headers.Authorization = `Bearer ${idToken}`;
          return fetchApi({ ...originalRequest });
        } else {
          removeCookie(REFREASH_TOKEN_NAME);
          removeCookie(ACCESS_TOKEN_NAME);
          removeCookie(ID_TOKEN_NAME);
          removeCookie(CUSTOMER_CODE_NAME);
          removeCookie(SERVICE_PATH);

          return (window.location.href = `${process.env.PUBLIC_URL}/login`);
        }
      }
      return Promise.reject(error);
    },
  );

  return defaultInstance({
    url,
    method,
    data: data || {},
    params: method === 'GET' || method === 'get' ? data : {},
    headers: {
      'Content-Type': contentType,
    },
    ...config,
  }).catch((error) => {
    console.error(
      `api error status: ${error.status}, message: ${error.message}`,
    );
    return (
      error.response || {
        data: {
          code: error.status,
          message: error.message,
        },
      }
    );
  });
};

// export const setupAxios = (axiosInstance, store) => {
//   /**
//    * request interceptor
//    */
//   axiosInstance.interceptors.request.use((config) => {

//     const idToken = getCookie(ID_TOKEN_NAME);

//     if (!config.url.includes('sign-in') && !idToken) {
//       return;
//     }

//     if (idToken && !config.url.includes('sign-in')) {
//       config.headers = {
//         ...config.headers,
//         Authorization: `Bearer ${idToken}`,
//         'Access-Control-Allow-Origin': '*',
//       };
//     }

//     return config;
//   });

interface DefaultApiResponse<T> {
  status: number;
  message: string;
  data: T;
}
export const afterAxios = (res: DefaultApiResponse<any>) => {
  if (res.status < 400) {
    return res.data;
  } else {
    throw Error(`${res.status}: ${res.message}`);
  }
};
