import React, { ReactElement } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';

import Login from './components/Login/Login';
import Main from './components/Main';
import Status from './components/History';
import Layout from './components/_common/Layout';
import useSilentAuth from './hooks/useSilentAuth';
import Trend from './components/Trend';
import ScrollToTop from './components/_common/ScrollToTop/ScrollToTop';

function RequireAuth({ children }: { children: ReactElement }): ReactElement {
  // Todo AuthProvider
  const isLogged = true;
  const location = useLocation();

  useSilentAuth();

  if (!isLogged) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}

function BaseRoute() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <ScrollToTop />
      <Routes>
        <Route element={<Layout />}>
          <Route path="login" element={<Login />} />

          <Route
            path="/"
            element={
              <RequireAuth>
                <Main />
              </RequireAuth>
            }
          />
          <Route
            path="/history"
            element={
              <RequireAuth>
                <Status />
              </RequireAuth>
            }
          />
          <Route
            path="/trend"
            element={
              <RequireAuth>
                <Trend />
              </RequireAuth>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default BaseRoute;
