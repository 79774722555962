import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import useManholeStore from 'src/store/ManholeStore';
import styled from 'styled-components';
import { DEVICE_STATUS_TAB_LIST } from './constant';

import AssetList from './AssetList';
import useAssetsList from './hooks/useAssetsList';
import useMain from '../hooks/useMain';

function MainAssetsList() {
  const { setSelectedListTab, tabId, setTabId, setActiveTab } =
    useManholeStore();
  const { handleClickAsset, statusAssets, selectedAsset } = useAssetsList();
  const { totalListCount } = useMain();

  const { t } = useTranslation(['home', 'common']);

  return (
    <MainAssetsListContainer>
      <MainListTitle>{t('AssetsList.title')}</MainListTitle>
      <MainStatusTab>
        {DEVICE_STATUS_TAB_LIST.map((tabAsset, i) => {
          return (
            <SelectedTab
              key={i}
              className={`${tabAsset.id} ${tabId === tabAsset.id && 'active'}`}
              onClick={() => {
                setSelectedListTab(tabAsset.status || '');
                setActiveTab(tabAsset.isActive);
                setTabId(tabAsset.id);
              }}
              color={tabAsset.color}
            >
              {t(`Status.${tabAsset.id}`, { ns: 'common' })}(
              {totalListCount[`${tabAsset.id}`]})
            </SelectedTab>
          );
        })}
      </MainStatusTab>
      <MainListWrapper>
        {_.isEmpty(statusAssets) ? (
          <div className="no-list">{t('AssetsList.no_list')}</div>
        ) : (
          statusAssets.map((asset, i) => (
            <AssetList
              key={i}
              asset={asset}
              assetName={asset.assetName}
              timestamp={asset.timestamp}
              activeStatus={asset.active}
              active={selectedAsset?.assetCode === asset.assetCode}
              onClick={() => handleClickAsset(asset.assetCode)}
            />
          ))
        )}
      </MainListWrapper>
    </MainAssetsListContainer>
  );
}

export default MainAssetsList;

const MainAssetsListContainer = styled.div`
  width: 100%;
  height: calc(100% - 298px);
  background-color: #fff;
  border: 1px solid #e4e5e6;
  box-sizing: border-box;
`;

const MainListTitle = styled.div`
  font-weight: 500;
  padding: 12px 12px 12px 20px;
  line-height: 1.25;
`;

const MainStatusTab = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
`;

const SelectedTab = styled.div`
  text-align: center;
  border-left: 1px solid #e4e5e6;
  border-bottom: 1px solid #e4e5e6;
  padding: 4px;
  box-sizing: border-box;
  border-radius: 10px 10px 0 0;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  border: 1px solid var(--statistics-background-color);

  &.active {
    border: ${(props) => `1px solid ${props.color}`};
    background-color: ${(props) => props.color};
    color: #fff;
  }

  &:hover {
    border: ${(props) => `1px solid ${props.color}`};
    opacity: 0.8;
  }
`;

const MainListWrapper = styled.div`
  width: 100%;
  height: calc(100% - 72px);
  overflow: auto;

  .no-list {
    text-align: center;
    padding-top: 20px;
  }
`;
