import { ShadowType } from 'src/types/shadow';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export type AssetType = ShadowType;

export interface AssetListType extends ShadowType {
  id?: string;
}
interface ManholeStoreType {
  assets: AssetType[];
  statusAssets: AssetType[];
  selectedAsset: AssetType | null;
  openToast: boolean;
  initMap: boolean;
  initTime: boolean;
  updateTime: number | null;
  // 메인 탭
  tabId: string;
  selectedListTab: string | undefined;
  activeTab: boolean | undefined;
  init: boolean;

  setAssets: (assets: AssetType[]) => void;
  setStatusAssets: (statusAssets: AssetType[]) => void;
  setSelectedAsset: (asset: AssetType | null) => void;
  setUnselectedAsset: () => void;
  setOpenToast: (toast: boolean) => void;
  setInitMap: (by: boolean) => void;
  setInitTime: (by: boolean) => void;
  setUpdateTime: (updateTime: number | null) => void;
  setInit: (by: boolean) => void;
  setResetManholeStore: () => void;

  setTabId: (tabId: string) => void;
  setSelectedListTab: (selectedListTab: string | undefined) => void;
  setActiveTab: (active: boolean | undefined) => void;
}

const useManholeStore = create<ManholeStoreType>()(
  devtools(
    (set): ManholeStoreType => ({
      assets: [],
      statusAssets: [],
      selectedAsset: null,
      openToast: false,
      initMap: false,
      initTime: false,
      updateTime: null,
      init: false,

      tabId: 'ALL',
      selectedListTab: '',
      activeTab: undefined,

      setAssets: (assets) => set(() => ({ assets })),
      setStatusAssets: (statusAssets) => set(() => ({ statusAssets })),
      setSelectedAsset: (asset) => set(() => ({ selectedAsset: asset })),
      setUnselectedAsset: () =>
        set(() => ({ selectedAsset: null, initMap: false })),
      setOpenToast: (openToast) => set(() => ({ openToast: !openToast })),
      setInitMap: (by) => set(() => ({ initMap: by })),
      setInitTime: (by) => set(() => ({ initTime: by })),
      setUpdateTime: (update) => set(() => ({ updateTime: update })),

      setTabId: (tabId) => set(() => ({ tabId })),
      setSelectedListTab: (selectedListTab) => set(() => ({ selectedListTab })),
      setActiveTab: (activeTab) => set(() => ({ activeTab })),

      setInit: (by) => set(() => ({ init: by })),
      setResetManholeStore: () =>
        set(() => ({
          assets: [],
          selectedAsset: null,
          openToast: false,
          initMap: false,
          initTime: false,
          updateTime: null,

          tabId: 'ALL',
          selectedListTab: '',
          activeTab: undefined,
        })),
    }),
  ),
);

export default useManholeStore;
