import React from 'react';
import { AssetSensorType } from 'src/types/shadow';

function useAssetSensor({ asset }) {
  /**
   * 디바이스 목록 상태별 개수
   */

  const sensorsStatus =
    asset.sensor &&
    Object.values(asset.sensor).map(
      (sensor) => (sensor as unknown as AssetSensorType).status,
    );

  const sensorCount =
    sensorsStatus &&
    sensorsStatus.reduce((acc: number, cur: number) => {
      acc[cur] = (acc[cur] || 0) + 1;
      return acc;
    }, []);

  return { sensorCount };
}

export default useAssetSensor;
