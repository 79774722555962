import React from 'react';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { format, setHours, setMinutes } from 'date-fns';
import { ko, ja } from 'date-fns/esm/locale';
import { useTranslation } from 'react-i18next';

export interface UseDateSearchPropsType {
  hookForm: any;
  isTrend: boolean;
  startTime: Date;
  endTime: Date;
  startOpen: boolean;
  endOpen: boolean;
  startRef: any;
  endRef: any;
  handleTimeChange: Function;
  handleTimeClick: Function;
}

function SelectDate({
  hookForm,
  isTrend,
  startTime,
  endTime,
  startOpen,
  endOpen,
  startRef,
  endRef,
  handleTimeChange,
  handleTimeClick,
}: UseDateSearchPropsType) {
  const { control } = hookForm;
  const { i18n, t } = useTranslation(['history']);

  const locale = i18n.language === 'ko' ? ko : i18n.language === 'ja' ? ja : '';

  return (
    <SelectDateContainer>
      <SelectDateWrapper>
        <span className="title">{t('Search.start')}</span>
        <Controller
          control={control}
          name="startDate"
          render={({ field }) => {
            return (
              <span>
                <StyledDateButton
                  readOnly
                  onClick={(e) => {
                    handleTimeClick(e, 'start');
                  }}
                  placeholder={
                    isTrend
                      ? startTime &&
                        format(Number(startTime), 'yyyy.MM.dd HH:mm')
                      : `${t('Search.select')}`
                  }
                  value={
                    (field.value &&
                      format(Number(field.value), 'yyyy.MM.dd HH:mm')) ||
                    ''
                  }
                />
                {startOpen && (
                  <StyledDateRangePicker ref={startRef}>
                    <DatePicker
                      locale={locale}
                      selected={field.value}
                      onChange={(e) => {
                        handleTimeChange(e, 'start');
                        field.onChange(e);
                      }}
                      startDate={field.value}
                      endDate={endTime}
                      selectsStart
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={10}
                      maxDate={endTime}
                      minTime={setHours(setMinutes(new Date(), 0), 0)}
                      maxTime={
                        endTime
                          ? startTime?.getDate() === endTime?.getDate()
                            ? endTime
                            : setHours(setMinutes(new Date(), 50), 23)
                          : setHours(setMinutes(new Date(), 50), 23)
                      }
                      inline
                      dateFormat="yyyy.MM.DD HH:mm:ss"
                    />
                  </StyledDateRangePicker>
                )}
              </span>
            );
          }}
        />
      </SelectDateWrapper>
      <SelectDateWrapper>
        <span className="title">{t('Search.end')}</span>
        <Controller
          control={control}
          name="endDate"
          render={({ field }) => {
            return (
              <span>
                <StyledDateButton
                  readOnly
                  onClick={(e) => {
                    handleTimeClick(e, 'end');
                  }}
                  placeholder={
                    isTrend
                      ? endTime && format(Number(endTime), 'yyyy.MM.dd HH:mm')
                      : `${t('Search.select')}`
                  }
                  value={
                    (field.value &&
                      format(Number(field.value), 'yyyy.MM.dd HH:mm')) ||
                    ''
                  }
                />

                {endOpen && (
                  <StyledDateRangePicker ref={endRef}>
                    <DatePicker
                      locale={locale}
                      selected={field.value}
                      onChange={(e) => {
                        handleTimeChange(e, 'end');
                        field.onChange(e);
                      }}
                      showTimeSelect
                      startDate={startTime}
                      endDate={field.value}
                      selectsEnd
                      timeFormat="HH:mm"
                      timeIntervals={10}
                      minDate={startTime}
                      minTime={
                        startTime
                          ? startTime?.getDate() === endTime?.getDate()
                            ? startTime
                            : setHours(setMinutes(new Date(), 0), 0)
                          : setHours(setMinutes(new Date(), 0), 0)
                      }
                      maxTime={setHours(setMinutes(new Date(), 50), 23)}
                      inline
                    />
                  </StyledDateRangePicker>
                )}
              </span>
            );
          }}
        />
      </SelectDateWrapper>
    </SelectDateContainer>
  );
}

const SelectDateContainer = styled.div`
  display: flex;
`;

const SelectDateWrapper = styled.div`
  margin-right: 40px;
`;

const StyledDateButton = styled.input`
  display: inline-block;
  width: 170px;
  height: 38px;
  padding: 8px 12px;
  margin: 12px;
  text-align: left;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;

  &:focus {
    border: 2px solid var(--main-color);
  }
`;

const StyledDateRangePicker = styled.div`
  position: absolute;
  z-index: 999;
`;

export default SelectDate;
