import React from 'react';
import { format } from 'date-fns';
import useManholeStore from 'src/store/ManholeStore';

function NowDateText() {
  const { updateTime } = useManholeStore();

  return (
    <>
      {updateTime !== null ? format(updateTime, 'yyyy.MM.dd HH:mm:ss') : null}
    </>
  );
}

export default NowDateText;
