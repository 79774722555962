import React, { useEffect } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import '../../styles/react-datepicker.css';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SelectDate from '../_common/SelectDate';
import useSelectDate from '../_common/SelectDate/hooks/useSelectDate';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';

export interface UseHistorySearchType {
  statusOptions: any[];
  hookForm: any;
  handleClearForm: () => void;
  handleSubmitSearch: (data: any) => void;
  isTrend: boolean;
}

function HistorySearch({
  statusOptions,
  hookForm,
  handleClearForm,
  handleSubmitSearch,
  isTrend,
}: UseHistorySearchType) {
  const {
    startTime,
    endTime,
    startOpen,
    endOpen,
    startRef,
    endRef,
    handleTimeChange,
    handleTimeClick,
  } = useSelectDate();

  const [searchParams] = useSearchParams();

  const { register, control, handleSubmit } = hookForm;
  const { t, i18n } = useTranslation(['history']);

  useEffect(() => {
    if (_.isEmpty(Array.from(searchParams))) {
      handleClearForm();
    }
  }, [handleClearForm, searchParams]);

  return (
    <HistorySearchContainer>
      <HistorySearchTitle>{t('Search.title')}</HistorySearchTitle>
      <HistorySearchWrapper>
        <form onSubmit={handleSubmit(handleSubmitSearch)}>
          <div>
            <span className="title">{t('Search.status')}</span>
            <Controller
              control={control}
              name="status"
              render={({ field }) => {
                return (
                  <StyledSelect
                    {...field}
                    options={statusOptions}
                    placeholder={`${t('Search.select')}`}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 5,
                      colors: {
                        ...theme.colors,
                        primary25: '#e4e8eb',
                        primary50: '#e4e8eb',
                        primary: '#a31f34',
                      },
                    })}
                  />
                );
              }}
            />
          </div>

          <SelectDate
            hookForm={hookForm}
            isTrend={isTrend}
            startTime={startTime}
            endTime={endTime}
            startOpen={startOpen}
            endOpen={endOpen}
            startRef={startRef}
            endRef={endRef}
            handleTimeChange={handleTimeChange}
            handleTimeClick={handleTimeClick}
          />

          <div></div>
          <div>
            <span className="title">{t('Search.name')}</span>
            <HistorySearchInput
              {...register('assetName')}
              type="text"
              placeholder={`${t('Search.input')}`}
            />
          </div>
          <div>
            <span className="title">{t('Search.code')}</span>
            <HistorySearchInput
              {...register('assetCode')}
              type="text"
              placeholder={`${t('Search.input')}`}
            />
          </div>

          <SearchButtonWrapper>
            <button className="reset" type="button" onClick={handleClearForm}>
              {t('Search.reset')}
            </button>
            <button type="submit" className="search">
              {t('Search.search')}
            </button>
          </SearchButtonWrapper>
        </form>
      </HistorySearchWrapper>
    </HistorySearchContainer>
  );
}

export default HistorySearch;

const HistorySearchContainer = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 16px 24px;
  box-sizing: border-box;
  margin-bottom: 16px;
`;

const HistorySearchTitle = styled.div`
  font-weight: 500;
  margin-bottom: 12px;
`;

const HistorySearchWrapper = styled.div`
  font-size: 14px;

  form {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;

    .title {
      display: inline-block;
      width: 60px;
    }

    input {
      width: 174px;
      height: 20px;
      border-radius: 5px;
      font-size: 14px;

      &:focus {
        outline-color: var(--main-color);
      }
    }
  }
`;

const StyledSelect = styled(Select)`
  display: inline-block;
  width: 200px;
  margin: 12px;
`;

const HistorySearchInput = styled.input`
  padding: 8px 12px;
  margin: 12px;
  border: 1px solid #ccc;
`;

const SearchButtonWrapper = styled.div`
  padding: 12px 0;
  button {
    padding: 8px 12px;
    margin-right: 8px;
    border: 1px solid var(--main-color);
    border-radius: 5px;
    font-weight: 500;
  }

  .reset {
    color: var(--main-color);
  }

  .search {
    background-color: var(--main-color);
    color: #fff;
  }
`;
