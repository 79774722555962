import _ from 'lodash';
import { AssetType } from 'src/store/ManholeStore';
import { StatusType } from 'src/types/shadow';

/**
 * 디바이스 상태 개수
 * @param param0
 * @returns
 */
export interface GetCountFromStatusType {
  assets: AssetType[];
  status: StatusType;
}
export const getCountFromStatus = ({
  assets,
  status,
}: GetCountFromStatusType): number => {
  const filterAssetFromStatus = assets.filter(
    (asset) => asset.status === status,
  );
  return !_.isEmpty(filterAssetFromStatus) ? filterAssetFromStatus.length : 0;
};

/**
 * rendom rgb
 */

export function getRandomRgb() {
  const num = Math.round(0xffffff * Math.random());
  const r = num >> 16;
  const g = (num >> 8) & 255;
  const b = num & 255;
  return 'rgb(' + r + ', ' + g + ', ' + b + ')';
}

/**
 * ISO time offset
 */
export const getTimeOffset = (date: Date) => {
  const offset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() - offset);
};
