import { useCallback, useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  createSearchParams,
  URLSearchParamsInit,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { getHistoryApi } from 'src/api/history/historyApi';
import { UsePaginationType } from 'src/hooks/usePagination';
import { DefaultPaginationType } from 'src/types/pagination';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { HistorySearchFormType } from '../types';
import { useTranslation } from 'react-i18next';
import useManholeStore, { AssetListType } from 'src/store/ManholeStore';

export const DefaultSearchForm: HistorySearchFormType = {
  status: '',
  startDate: '',
  endDate: '',
  assetName: '',
  assetCode: '',
};

export interface UseHistorySearchPropsType {
  searchPagination: UsePaginationType;
}

function useHistorySearch({ searchPagination }: UseHistorySearchPropsType) {
  const { init, setInit } = useManholeStore();
  const { page, size, handlePageChange } = searchPagination;

  const [searchParams] = useSearchParams();
  const { pathname, search } = useLocation();
  const querySearchParam = useMemo(() => new URLSearchParams(search), [search]);
  const historyId = querySearchParam.get('historyId');
  const isAlarm = querySearchParam.get('isAlarm');

  const navigate = useNavigate();
  const hookForm = useForm<HistorySearchFormType>({
    defaultValues: { ...DefaultSearchForm },
  });

  const { t } = useTranslation(['common']);

  const statusOptions = useMemo(() => {
    return [
      { value: '', label: `${t('Status.ALL')}` },
      { value: 'NORMAL', label: `${t('Status.NORMAL')}` },
      { value: 'WARNING', label: `${t('Status.WARNING')}` },
      { value: 'DANGER', label: `${t('Status.DANGER')}` },
    ];
  }, [t]);

  /**
   * param
   */

  const createHistorySearchParam = useCallback(
    (data: HistorySearchFormType) => {
      navigate({
        pathname: '/history',
        search: `?${createSearchParams(data as string).toString()}`,
      });
    },
    [navigate],
  );

  const convertQueryParam = useCallback(() => {
    const queryParam = {
      ...Object.fromEntries(Array.from(searchParams)),
    };

    return { ...queryParam };
  }, [searchParams]);

  const historyQueryParam = useMemo(() => {
    const queryParam = convertQueryParam();
    const param: any = {
      ...queryParam,
      page,
      size,
    };
    delete param.historyId;
    delete param.isAlarm;

    return param;
  }, [convertQueryParam, page, size]);

  /**
   * param 빈 값 제거
   */

  const filterEmptyParam = useCallback((data: HistorySearchFormType) => {
    delete data?.historyId;

    data.status = data.status?.value;
    data.startDate = data.startDate ? data.startDate.toISOString() : '';

    data.endDate = data.endDate
      ? data.endDate.toISOString()
      : new Date().toISOString();

    return Object.keys(data).reduce((cur, prev) => {
      if (data[prev]) {
        cur[prev] = data[prev];
      }
      return cur;
    }, {});
  }, []);

  /**
   * QueryParam 존재하면 셋
   */
  useEffect(() => {
    const queryParam = convertQueryParam();

    if (!_.isEmpty(queryParam) && !init) {
      if (queryParam.startDate) {
        queryParam.startDate = new Date(
          queryParam.startDate,
        ) as unknown as string;
      }
      if (queryParam.endDate) {
        queryParam.endDate = new Date(queryParam.endDate) as unknown as string;
      }
      if (queryParam.status) {
        queryParam.status = statusOptions.find(
          (item) => item.value === queryParam.status,
        ) as unknown as string;
      }

      hookForm.reset(queryParam);
    }

    setInit(true);
  }, [convertQueryParam, hookForm, init, setInit, statusOptions]);

  /**
   * 검색 초기화
   */

  const handleClearForm = useCallback(() => {
    hookForm.reset(DefaultSearchForm);
    navigate({
      pathname: '/history',
    });
    handlePageChange(0);
  }, [handlePageChange, hookForm, navigate]);

  /**
   * 검색
   */

  const handleSubmitSearch = useCallback(
    (data) => {
      navigate({
        pathname: '/history',
      });
      handlePageChange(0);
      const convertParam = filterEmptyParam(data);

      createHistorySearchParam(convertParam);
    },
    [createHistorySearchParam, filterEmptyParam, handlePageChange, navigate],
  );

  /**
   * 히스토리
   */

  const getHistoryQuery = useQuery<DefaultPaginationType<AssetListType>>(
    ['history', historyQueryParam],
    () => getHistoryApi({ ...historyQueryParam }),
    {
      onSuccess: (res) => {
        if (isAlarm) {
          if (page === 0) {
            const param = {
              ...historyQueryParam,
            };

            delete param.isAlarm;
            navigate(
              `${pathname}?historyId=${res.content[0].id}&${createSearchParams(
                param as URLSearchParamsInit,
              )}
              `,
            );
          }
          handlePageChange(0);
        }
      },
      refetchOnWindowFocus: false,
    },
  );

  /**
   * 히스토리 리스트 클릭 핸들러
   */

  const handleClickHistory = useCallback(
    (id: string) => {
      navigate(
        `${pathname}?${id === historyId ? '' : `historyId=${id}`}${
          !_.isEmpty(historyQueryParam)
            ? `&${createSearchParams(historyQueryParam as URLSearchParamsInit)}`
            : ''
        }`,
      );
    },
    [historyId, historyQueryParam, navigate, pathname],
  );

  /**
   * 히스토리 페이지네이션 클릭 핸들러
   */

  const handleClickPagination = useCallback(
    (newPage: number) => {
      handlePageChange(newPage);

      navigate(
        `${pathname}?${createSearchParams(
          historyQueryParam as URLSearchParamsInit,
        )}`,
      );
    },
    [handlePageChange, historyQueryParam, navigate, pathname],
  );

  return {
    statusOptions,
    hookForm,
    handleClearForm,
    handleSubmitSearch,
    handleClickHistory,
    page,
    size,
    getHistoryQuery,
    historyId,
    isAlarm,
    handleClickPagination,
  };
}

export default useHistorySearch;
