import qs from 'qs';
import { ProjectsType } from 'src/store/AuthStore';
import {
  getCookie,
  REFREASH_TOKEN_NAME,
  CUSTOMER_CODE_NAME,
} from 'src/utils/cookies';
import { ApiPathPrefix } from '../apiPathPrefix';
import { afterAxios, fetchApi } from '../config';

export interface SignInRequestType {
  tenant_id: string;
  username: string;
  password: string;
  refreshToken?: string;
  customerCode?: string;
}

export interface SignInResponseType {
  accessToken: string;
  expiresIn: number;
  idToken: string;
  refreshToken: string;
  tokenType: string;
}

export const signInApi = async (req: SignInRequestType) => {
  // const params = new URLSearchParams();
  const params = {
    ...req,
    grant_type: 'password',
  };

  return await fetchApi({
    url: `${ApiPathPrefix.OAUTH}`,
    method: 'POST',
    contentType: 'application/x-www-form-urlencoded',
    data: qs.stringify(params),
  });
};

export interface RefreshTokenRequestType {
  refreshToken: string;
  customerCode: string;
  grant_type: 'refresh_token';
}

export const silentSignInApi = async () => {
  const refreshToken = getCookie(REFREASH_TOKEN_NAME);
  const customerCode = getCookie(CUSTOMER_CODE_NAME);
  if (refreshToken && customerCode) {
    const params = {
      grant_type: 'refresh_token',
      refreshToken,
      customerCode,
    };

    return await fetchApi({
      url: `${ApiPathPrefix.OAUTH}`,
      method: 'POST',
      contentType: 'application/x-www-form-urlencoded',
      data: qs.stringify(params),
    });
  } else {
    // CusutomCode 가 없어서 refrash가 안탄다.
    return {
      status: 403,
    };
  }
};

/**
 * Get Project
 */

export interface GetProjectApiResponseType {
  projects: ProjectsType[];
}

interface GetProjectsApiRequestType {
  serviceCode: string;
}

export const getProjectsApi = async (req: GetProjectsApiRequestType) => {
  return await fetchApi({
    url: `/me/services/${req.serviceCode}/projects`,
    method: 'GET',
  }).then(afterAxios);
};
