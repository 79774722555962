import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { MapContainer, TileLayer, useMap, useMapEvents } from 'react-leaflet';
import L, { LatLngBoundsExpression } from 'leaflet';

import CustomMarker from './CustomMarker/CustomMarker';
import _ from 'lodash';
import useManholeStore from 'src/store/ManholeStore';

function Map() {
  const {
    assets,
    statusAssets,
    selectedAsset,
    setUnselectedAsset,
    initMap,
    setInitMap,
  } = useManholeStore();

  const [map, setMap] = useState<L.Map | null>(null);
  const [targetAssetCode, setTargetAssetCode] = useState<string>(
    selectedAsset?.assetCode || '',
  );

  // get Center
  const bounds = useMemo(() => {
    if (!_.isEmpty(assets) && !selectedAsset) {
      return assets
        .filter((asset) => asset.geoLocation?.gps)
        .map((asset) => [asset.geoLocation?.gps]);
    }
    return [[0, 0]];
  }, [assets, selectedAsset]);

  /**
   * Leaflet Controller
   * 지도 위치 결정
   */
  function MapController({ bounds }) {
    const map = useMap();

    useEffect(() => {
      if (!initMap && !_.isEqual(bounds[0], [0, 0])) {
        map.fitBounds(bounds);

        setInitMap(true);
      }
    }, [bounds, map]);

    return null;
  }

  function MapEvent() {
    const map = useMapEvents({
      popupopen: (e) => {
        if (
          selectedAsset &&
          selectedAsset.geoLocation &&
          selectedAsset.geoLocation.gps
        ) {
          setTargetAssetCode(selectedAsset?.assetCode || '');

          const [lng, lat] = selectedAsset.geoLocation.gps;

          const zoomScale = map.getZoom();
          map
            .setView({ lng, lat })
            .fitBounds([[lng, lat]])
            .setZoom(zoomScale, {
              animate: false,
            });
        }
      },
      popupclose: (e) => {
        if (selectedAsset?.assetCode === targetAssetCode || !targetAssetCode) {
          setTargetAssetCode('');
          setUnselectedAsset();
        }
      },
    });

    return null;
  }

  return (
    <MapWrapper>
      <MapContainer
        style={{
          width: '100%',
          height: '100%',
        }}
        scrollWheelZoom={true}
        bounds={bounds as LatLngBoundsExpression}
        ref={setMap}
        maxZoom={17}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png"
        />
        <MapController bounds={bounds} />
        <MapEvent />

        {map &&
          statusAssets.map((asset) => {
            const isActive = asset.assetCode === selectedAsset?.assetCode;

            return (
              <CustomMarker
                asset={asset}
                key={asset.assetCode}
                map={map}
                isActive={!!(isActive && asset)}
              />
            );
          })}
      </MapContainer>
    </MapWrapper>
  );
}

export default Map;

const MapWrapper = styled.div`
  height: 100%;
  margin: auto;

  .leaflet-div-icon {
    background: none;
    border: none;
  }
`;
