import login from './login.json';
import header from './header.json';
import home from './home.json';
import trend from './trend.json';
import history from './history.json';
import common from './common.json';

export default {
  login,
  header,
  home,
  trend,
  history,
  common,
};
