import React from 'react';
import styled from 'styled-components';
import { v4 } from 'uuid';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { AssetInfoType } from '../_common/Map/types';

interface SelectedAssetInfoPropsType {
  asset: AssetInfoType;
}

const SelectedAssetInfo = ({ asset }: SelectedAssetInfoPropsType) => {
  const { assetCode, geoLocation, remark, deviceVersion } = asset;

  const { t } = useTranslation(['home']);

  return (
    <SelectedAssetInfoWrapper>
      <AssetInfoContentWrapper>
        <div className="name">{t('Marker.code')}</div>
        <div className="content">{assetCode}</div>
      </AssetInfoContentWrapper>
      <AssetInfoContentWrapper>
        {geoLocation && geoLocation.gps && (
          <>
            <div className="name">{t('Marker.location')}</div>
            <div className="content ">{geoLocation.address}</div>
          </>
        )}
      </AssetInfoContentWrapper>
      <AssetInfoContentWrapper>
        <div className="name">H/W.ver</div>
        <div className="content">{deviceVersion?.hardware || ''}</div>
      </AssetInfoContentWrapper>
      <AssetInfoContentWrapper>
        <div className="name">F/W.ver</div>
        <div className="content">{deviceVersion?.firmware || ''}</div>
      </AssetInfoContentWrapper>

      {!_.isEmpty(remark) &&
        Object.keys(remark).map((item) => {
          return (
            <AssetInfoContentWrapper key={v4()}>
              <div className="name">
                {item === '담당자'
                  ? `${t('Marker.manager')}`
                  : item === '위치'
                  ? `${t('Marker.location_detail')}`
                  : item}
              </div>
              <div className="content">{remark[item]}</div>
            </AssetInfoContentWrapper>
          );
        })}
    </SelectedAssetInfoWrapper>
  );
};

const SelectedAssetInfoWrapper = styled.div`
  font: normal 14px var(--main-font);
  box-sizing: border-box;
  padding-bottom: 16px;
  display: flex;

  gap: 15px;
  align-items: left;
  justify-content: center;
  font-size: 14px;
  white-space: nowrap;
  flex-direction: column;
`;

const AssetInfoContentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  .name {
    width: 25%;
    font-weight: 500;
    font-size: 15px;
  }
  .content {
    width: 75%;
    word-break: break-word;
    white-space: initial;
  }
`;

export default React.memo(SelectedAssetInfo);
