import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuthStore from 'src/store/AuthStore';
import { getCookie, ID_TOKEN_NAME } from '../utils/cookies';

export default function useSilentAuth() {
  const { loggedIn, setSignIn } = useAuthStore();
  const MH_IT = getCookie(ID_TOKEN_NAME);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (MH_IT && !loggedIn) {
      setSignIn();
    }
  }, [MH_IT, loggedIn, setSignIn]);

  /**
   * not Server redirect
   */
  useEffect(() => {
    if (!loggedIn && !MH_IT && location.pathname !== '/login') {
      navigate('/login');
    }
  }, [MH_IT, location, loggedIn, navigate]);
}
