import React, { useCallback, useEffect, useState } from 'react';
import { ShadowStateType } from 'src/types/shadow';
import useToastAlarm from 'src/hooks/useToastAlarm';
import _ from 'lodash';
import { useQueryClient } from '@tanstack/react-query';
import { io } from 'socket.io-client';
import useAuthStore from 'src/store/AuthStore';
import useManholeStore from 'src/store/ManholeStore';

function useMqtt() {
  const queryClient = useQueryClient();
  const { loggedIn } = useAuthStore();
  const { initTime } = useManholeStore();
  const { openToastAlarm } = useToastAlarm();

  const [socket, setSocket] = useState<any>(null);

  const afterReceiveMqtt = useCallback(
    (res: ShadowStateType) => {
      console.log('mqttres', res);
      if (_.isEmpty(res.state)) return;
      if (!res.state.reported) return;

      const { reported } = res.state;

      queryClient.invalidateQueries(['assets']);
      queryClient.invalidateQueries(['assets-status']);
      queryClient.invalidateQueries(['history']);
      queryClient.invalidateQueries(['alarm']);

      openToastAlarm(reported);
    },
    [openToastAlarm, queryClient],
  );

  useEffect(() => {
    if (loggedIn && !initTime) {
      const socketIo = io(`${window._env_.REACT_APP_BASE_MQTT_URL}`);
      if (socketIo) {
        setSocket(socketIo);
      }
      socketIo.on('connect', () => {
        console.log('connect');
      });

      socketIo.on('receive message', (res) => {
        console.log('receive message: ', res);
        afterReceiveMqtt(res);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [afterReceiveMqtt, initTime, loggedIn]);

  useEffect(() => {
    if (!loggedIn && socket) {
      console.log('disconnect');
      socket.disconnect();
      setSocket(null);
    }
  }, [loggedIn, socket]);

  return {};
}

export default useMqtt;
