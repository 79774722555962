import { TrendChartType } from 'src/components/Trend/types';
import { ShadowType } from 'src/types/shadow';
import { getCookie } from 'src/utils/cookies';
import { ApiPathPrefix } from '../apiPathPrefix';
import { afterAxios, fetchApi } from '../config';

/**
 * 트렌드 차트 데이터
 */

export interface GetTrendApiRequestType {
  assetCodes?: any;
  startDate?: Date;
  endDate?: Date;
}

export interface GetTrendApiResponseType {
  content: TrendChartType[];
}

export const getTrendApi = async (req: GetTrendApiRequestType) => {
  return await fetchApi({
    url: `${getCookie('MH_PATH')}${ApiPathPrefix.STATISTICAL}`,
    method: 'GET',
    config: {
      params: { ...req },
    },
  }).then(afterAxios);
};

/**
 * export Excel
 */

export interface GetExcelApiRequestType extends GetTrendApiRequestType {
  timeZone: number;
}

export const getExcelApi = async (req: GetExcelApiRequestType) => {
  return await fetchApi({
    url: `${getCookie('MH_PATH')}${ApiPathPrefix.EXCEL}`,
    method: 'GET',
    contentType:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
    config: {
      responseType: 'arraybuffer',
      params: { ...req },
    },
  }).then(afterAxios);
};
