import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import { getImageApi } from 'src/api/images/imageApi';
import useManholeStore from 'src/store/ManholeStore';
import styled from 'styled-components';
import Map from '../_common/Map';
import MapCanvas from '../_common/MapCanvas';
import MainAssetsList from './MainAssetsList/MainAssetsList';
import MainSelectedSensor from './MainSelectedSensor';
import MainTotalAssets from './MainTotalAssets/MainTotalAssets';

function Main() {
  const [size, setSize] = useState({ width: 0, height: 0 });
  const canvaseRef = useRef<any>(null);

  useEffect(() => {
    if (canvaseRef.current) {
      setSize({
        width: canvaseRef.current.offsetWidth,
        height: canvaseRef.current.offsetHeight,
      });
    }
  }, []);

  const getImageQuery = useQuery({
    queryKey: ['images'],
    queryFn: getImageApi,
    enabled: window._env_.REACT_APP_ENVIRONMENT === 'ONPREMISE',
  });

  return (
    <MainContainer>
      <MainContentBox>
        <MainLeftWrapper>
          <MainTotalAssets />
          <MainAssetsList />
        </MainLeftWrapper>
        <MainCenterWrapper ref={canvaseRef}>
          {window._env_.REACT_APP_ENVIRONMENT === 'ONPREMISE' ? (
            <MapCanvas size={size} imageUrl={getImageQuery?.data?.imageUrl} />
          ) : (
            <Map />
          )}
        </MainCenterWrapper>
        <MainSelectedSensor />
      </MainContentBox>
    </MainContainer>
  );
}

export default Main;

const MainContainer = styled.div`
  font: normal 16px var(--main-font);
  width: 100%;
  height: 100%;
  margin-top: 80px;
  box-sizing: border-box;
`;

const MainContentBox = styled.div`
  display: flex;
  box-sizing: border-box;
  height: calc(100vh - 100px);
  width: 100%;
  position: relative;
`;

const MainLeftWrapper = styled.div`
  width: 450px;
  box-sizing: border-box;
`;

const MainCenterWrapper = styled.div`
  box-sizing: border-box;
  width: calc(100% - 400px);
  height: 100%;
  margin-left: 20px;
`;
