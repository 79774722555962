import React from 'react';
import styled from 'styled-components';
import useAssetStatus from './hooks/useAssetStatus';
import _ from 'lodash';
import { v4 } from 'uuid';
import { SensorNameType } from 'src/types/shadow';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import LineChart from '../_common/LineChart';
import useSensor from 'src/hooks/useSensor';
import StatusChart from './StatusChart';
import 'swiper/css';
import 'swiper/css/navigation';
import { useTranslation } from 'react-i18next';
import { TrendChartType } from './types';
interface AssetStatusPropsType {
  trendAsset: TrendChartType;
  isTrend: boolean;
}

function AssetStatus({ trendAsset, isTrend }: AssetStatusPropsType) {
  const {
    registerChartData,
    sortedSensorList,
    sensorStatusCountArr,
    totalCountArr,
  } = useAssetStatus({
    trendAsset,
  });
  const { getSensorQuery } = useSensor();
  const { t } = useTranslation(['trend']);

  return (
    <AssetStatusContainer>
      <AssetStatusLeft>
        <AssetName>{trendAsset.assetName}</AssetName>
        <StatusChart
          chartData={totalCountArr || [0, 0, 0, 1]}
          title={`${t('TrendChart.chart')}`}
          tooltipList={sensorStatusCountArr}
          isTrend={isTrend}
        />
      </AssetStatusLeft>

      <AssetStatusRight>
        {_.isEmpty(trendAsset.sensor) ? (
          <div>{t('TrendChart.no_data')}</div>
        ) : (
          <Swiper
            slidesPerView={3}
            spaceBetween={10}
            slidesPerGroup={1}
            loopFillGroupWithBlank={true}
            navigation={true}
            modules={[Navigation]}
            className="mySwiper"
            initialSlide={0}
          >
            {!_.isEmpty(sortedSensorList) &&
              sortedSensorList
                .filter((sensorName) => sensorName !== 'imu')
                .map((sensorName) => {
                  const sensorData = getSensorQuery?.data?.sensor;
                  const chartData = registerChartData(
                    trendAsset,
                    sensorName as SensorNameType,
                  );
                  const chartDataList = chartData?.datasets[0].data;
                  const sortedChartData = _.sortBy(chartDataList, 'y').filter(
                    (data) => data.y !== undefined,
                  );

                  return (
                    <SwiperSlide key={sensorName} id={v4()}>
                      <LineChart
                        sensorName={sensorName}
                        chartData={chartData}
                      />
                      {chartData
                        ? sensorName !== 'open' && (
                            <div className="sensor_data">
                              <span>
                                {`Min: ${sortedChartData?.[0]?.y} ${sensorData[sensorName].unit}`}
                              </span>
                              <span>
                                {`Max: ${sortedChartData.reverse()?.[0]?.y} ${
                                  sensorData[sensorName].unit
                                }`}
                              </span>
                              <span>
                                {`Avg: ${
                                  chartDataList &&
                                  parseFloat(
                                    (
                                      _.sumBy(
                                        sortedChartData,
                                        (data: any) => data.y,
                                      ) / sortedChartData.length
                                    ).toFixed(2),
                                  )
                                } ${sensorData[sensorName].unit}`}
                              </span>
                            </div>
                          )
                        : ''}
                    </SwiperSlide>
                  );
                })}
          </Swiper>
        )}
      </AssetStatusRight>
    </AssetStatusContainer>
  );
}

export default React.memo(AssetStatus);

const AssetStatusContainer = styled.div`
  background-color: white;
  padding: 20px;
  margin-bottom: 15px;

  display: flex;
`;

const AssetStatusLeft = styled.div`
  width: 20%;
  border: 1px solid var(--statistics-chart-color);
  box-sizing: border-box;
  text-align: center;
`;

const AssetName = styled.div`
  font-weight: 500;
  padding: 10px 0;
  border-bottom: 1px solid var(--statistics-chart-color);
`;

const AssetStatusRight = styled.div`
  padding-top: 15px;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: white;
  height: 300px;
  box-sizing: border-box;

  .swiper {
    width: 100%;
    height: 100%;
    padding: 0 20px 36px 20px;
    margin-left: 20px;
    box-sizing: border-box;

    --swiper-theme-color: var(--main-color);
  }
  .swiper-wrapper {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
  }

  .swiper-button-prev,
  .swiper-button-next {
    &:after {
      font-size: 30px;
      font-weight: bold;
    }
  }

  .swiper-button-prev {
    left: 0;
  }
  .swiper-button-next {
    right: 0;
  }

  .sensor_data {
    margin-top: 20px;
    text-align: center;
    font-size: 14px;

    span {
      margin: 0 8px;
    }
  }
`;
