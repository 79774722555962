import React from 'react';
import styled from 'styled-components';
import useAssetsList from '../MainAssetsList/hooks/useAssetsList';
import MainSelectedSensorItem from './MainSelectedSensorItem';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { subDays } from 'date-fns';
import useMainSelectedSensor from './hooks/useMainSelectedSensor';
import SelectedAssetInfo from 'src/components/SelectedAssetInfo';
import useCustomMarker from 'src/components/_common/Map/CustomMarker/hooks/useCustomMarker';

function MainSelectedSensor() {
  const { selectedAsset } = useAssetsList();
  const navigate = useNavigate();
  const { t } = useTranslation(['home']);
  const { selectedSortedSensors } = useMainSelectedSensor({ selectedAsset });

  const { getAssetInfoQuery } = useCustomMarker({
    isActive: !!selectedAsset,
  });

  return (
    <SelectedSensorContainer>
      <SelectedSensorTitle>
        <div className="title">{t('SelectedSensor.title')}</div>
        {selectedAsset && (
          <span
            onClick={() => {
              navigate(
                `/trend?assetCodes=${
                  selectedAsset?.assetCode
                }&startDate=${subDays(
                  new Date(),
                  3,
                ).toISOString()}&endDate=${new Date().toISOString()}`,
              );
            }}
          >
            {t('SelectedSensor.detail')}
          </span>
        )}
      </SelectedSensorTitle>

      {window._env_.REACT_APP_ENVIRONMENT === 'ONPREMISE' && selectedAsset && (
        <CustomPopupContainer
          style={{
            background: '#fff',
            padding: '5px',
          }}
        >
          <CustomPopupHeader>
            <h3>{selectedAsset.assetName}</h3>
          </CustomPopupHeader>
          <CustomPopupContentBox>
            {getAssetInfoQuery.data && (
              <SelectedAssetInfo asset={getAssetInfoQuery.data} />
            )}
          </CustomPopupContentBox>
        </CustomPopupContainer>
      )}
      <SensorList>
        {selectedSortedSensors.map((sensor, i) => (
          <MainSelectedSensorItem
            key={i}
            assetCode={selectedAsset?.assetCode}
            sensor={sensor}
          />
        ))}
      </SensorList>
    </SelectedSensorContainer>
  );
}

export default MainSelectedSensor;

const SelectedSensorContainer = styled.div`
  background: #fff;
  position: absolute;
  z-index: 1000;
  width: 280px;
  border-left: 1px solid #e4e5e6;
  box-sizing: border-box;
  border-radius: 10px 10px 0 0;
  cursor: auto;
  bottom: 0;
  right: 0;
  box-shadow: -2px -2px 5px #999;
`;

const SelectedSensorTitle = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #dedede;
  padding: 8px;
  border-radius: 10px 10px 0 0;

  span {
    font-size: 14px;
    color: var(--badge-text-color);
    background-color: #636363;
    border-radius: 50px;
    line-height: 20px;
    padding: 2px 10px 0 10px;
    margin-left: 12px;
    cursor: pointer;
  }
`;

const SensorList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const CustomPopupContainer = styled.div`
  /* width: 312px; */
  padding: 16px;
  border-start-start-radius: 10px;
  margin-bottom: 5px;
`;

const CustomPopupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--border-color);

  div {
    display: flex;
    align-items: center;

    h3 {
      font: var(--subtitle-font);
    }
    span {
      color: var(--badge-text-color);
      background-color: #aaadad;
      border-radius: 50px;
      line-height: 22px;
      padding: 2px 10px 0 10px;
      margin-left: 12px;
      cursor: pointer;
    }
  }
  & > button {
    color: #212529;
    &:hover {
      color: #000;
    }
  }
`;

const CustomPopupContentBox = styled.div`
  width: 100%;
  box-sizing: border-box;

  div {
    &:last-child {
      padding-bottom: 0;
    }
  }
`;
