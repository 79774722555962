import React, { useCallback, useState } from 'react';

export interface UsePaginationType {
  page: number;
  size: number;
  handlePageChange: (newPage: number) => void;
  handleSizeChange: (newSize: number) => void;
}

function usePagination() {
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(20);
  const [alarmSize, setalarmSize] = useState<number>(10);

  const handlePageChange = useCallback((newPage: number): void => {
    setPage(newPage);
  }, []);

  const handleSizeChange = useCallback(
    (newSize: number): void => {
      setSize(newSize);
    },
    [setSize],
  );

  return {
    page,
    size,
    alarmSize,
    handlePageChange,
    handleSizeChange,
  };
}

export default usePagination;
