import { getCookie } from './../utils/cookies';
export enum ApiPathPrefix {
  LATEST = '/latest',
  ALARM = '/alarm',
  HISTORY = '/history',
  STATISTICAL = '/statistical',
  OAUTH = '/oauth/token',
  PRINCIPAL = '/oauth/principal',
  LOCAL = '/asset-data-history/local',
  ASSETS = '/assets',
  SENSOR = '/sensor',
  EXCEL = '/excel',
}
