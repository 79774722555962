import React from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { StatusType } from 'src/types/shadow';

interface StatusLabelPropsType {
  status: StatusType;
}

function StatusLabel({ status }: StatusLabelPropsType) {
  const { t } = useTranslation(['common']);

  return (
    <StatusLabelContainer
      className={`${status} === 'ERROR'
    ? NORMAL : ${status.toUpperCase()}`}
    >
      {status
        ? status === 'ERROR'
          ? t(`Status.NORMAL`)
          : t(`Status.${status.toUpperCase()}`)
        : ''}
    </StatusLabelContainer>
  );
}

export default StatusLabel;

const StatusLabelContainer = styled.div`
  border-radius: 15px;
  width: 50px;
  padding: 0 4px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal var(--font-size-14) var(--main-font);
  line-height: 24px;

  @media all and (max-width: 1540px) {
    height: 24px;
    font-size: 14px;
  }

  &.NORMAL {
    background-color: var(--normal-badge-background-color);
    color: var(--badge-text-color);
  }
  &.WARNING {
    background-color: var(--warning-badge-background-color);
    color: var(--badge-text-color);
  }
  &.DANGER {
    background-color: var(--danger-badge-background-color);
    color: var(--badge-text-color);
  }
  &.INACTIVE {
    background-color: var(--inactive-badge-background-color);
    color: var(--badge-text-color);
  }
`;
