import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { AssetInfoType } from '../../types';
import useManholeStore from 'src/store/ManholeStore';
import { getAssetInfoApi } from 'src/api/asset/assetApi';

function useCustomMarker({ isActive }) {
  const { selectedAsset } = useManholeStore();

  const getAssetInfoQuery = useQuery<AssetInfoType>(
    ['asset-info'],
    () => getAssetInfoApi(selectedAsset?.assetCode),
    {
      enabled: isActive,
    },
  );

  return {
    getAssetInfoQuery,
    selectedAsset,
  };
}

export default useCustomMarker;
