import React from 'react';
import styled from 'styled-components';
import { v4 } from 'uuid';

interface PaginationType {
  total: number;
  size: number;
  page: number;
  handlePageChange: Function;
}

function Pagination({ total, size, page, handlePageChange }: PaginationType) {
  const pageNumber = Math.ceil(total / size);
  const totalPages = total;
  const pageArr = Array.from({ length: totalPages }, (_, i) => i);

  const pagesToShow = 5;
  const startPage = Math.floor(page / pagesToShow) * pagesToShow;
  const endPage = Math.min(startPage + pagesToShow, pageNumber);

  const pages = pageArr.slice(startPage, endPage).map((i) => i + 1);

  return (
    <PaginationContainer>
      <Prev>
        <button
          className="prev"
          onClick={() => handlePageChange(0)}
          disabled={page === 0}
        >
          <img src={`${process.env.PUBLIC_URL}/img/page_prev_arrow.png`} />
          <img src={`${process.env.PUBLIC_URL}/img/page_prev_arrow.png`} />
        </button>
        <button
          onClick={() => handlePageChange(page - 1)}
          disabled={page === 0}
        >
          <img src={`${process.env.PUBLIC_URL}/img/page_prev_arrow.png`} />
        </button>
      </Prev>
      <PageNumber>
        {pages.map((item, index) => {
          return typeof item === 'number' ? (
            <button
              key={v4()}
              className={page + 1 === item ? 'on' : ''}
              onClick={() => handlePageChange(item - 1)}
            >
              {item}
            </button>
          ) : (
            <span key={v4()}>{item}</span>
          );
        })}
      </PageNumber>
      <Next>
        <button
          onClick={() => handlePageChange(page + 1)}
          disabled={page === pageNumber - 1}
        >
          <img src={`${process.env.PUBLIC_URL}/img/page_next_arrow.png`} />
        </button>
        <button
          className="next"
          onClick={() => handlePageChange(pageNumber - 1)}
          disabled={page === pageNumber - 1}
        >
          <img src={`${process.env.PUBLIC_URL}/img/page_next_arrow.png`} />
          <img src={`${process.env.PUBLIC_URL}/img/page_next_arrow.png`} />
        </button>
      </Next>
    </PaginationContainer>
  );
}

export default Pagination;

const PaginationContainer = styled.div`
  padding: 40px 0;
  text-align: center;
`;

const PageNumber = styled.span`
  button {
    font-size: 16px;
    color: #707070;
    margin: 0 16px;
    cursor: pointer;
  }
  .on {
    font-weight: bold;
    color: #a31f34;
  }
`;

const Prev = styled.span`
  button {
    margin-right: 28px;
    cursor: pointer;
  }
  img {
    width: 12px;
    height: 12px;
  }
  .prev {
    & img:nth-child(2) {
      margin-left: -4px;
    }
  }
`;
const Next = styled.span`
  button {
    margin-left: 28px;
    cursor: pointer;
  }
  img {
    width: 12px;
    height: 12px;
  }
  .next {
    & img:nth-child(2) {
      margin-left: -4px;
    }
  }
`;
