import { useCallback, useEffect, useMemo, useState } from 'react';
import Amplify, { PubSub } from 'aws-amplify';
import { awsConfig, AwsConfigType } from '../utils/awsConfig';
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
import { useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { ShadowResponseType } from 'src/types/shadow';
import useToastAlarm from './useToastAlarm';
import useManholeStore from 'src/store/ManholeStore';
import useAuthStore from 'src/store/AuthStore';

function init(awsConfig: AwsConfigType) {
  Amplify.configure({
    Auth: {
      userPoolId: awsConfig.cognitoUserPoolId,
      userPoolWebClientId: awsConfig.cognitoUserPoolClientId,
      identityPoolId: awsConfig.cognitoIdentityPoolId,
      region: awsConfig.region,
    },
  });

  Amplify.addPluggable(
    new AWSIoTProvider({
      aws_pubsub_region: awsConfig.region,
      aws_pubsub_endpoint: `wss://${awsConfig.mqttBrokerEndpoint}/mqtt`,
    }),
  );
}

function useIotCore() {
  init(awsConfig);
  const [pubSub, setPubSub] = useState<any>(null);
  const queryClient = useQueryClient();

  const { loggedIn, projects } = useAuthStore();
  const { openToastAlarm } = useToastAlarm();
  const { initTime, setUpdateTime } = useManholeStore();

  const projectCode = useMemo(() => {
    return projects?.code;
  }, [projects]);

  const afterReciveMqtt = useCallback(
    (res: ShadowResponseType) => {
      if (!res.value.state.reported) return;
      if (_.isEmpty(res.value.state)) return;

      const { reported } = res.value.state;

      queryClient.invalidateQueries(['assets']);
      queryClient.invalidateQueries(['assets-status']);
      queryClient.invalidateQueries(['history']);
      queryClient.invalidateQueries(['alarm']);

      setUpdateTime(reported.timestamp);

      openToastAlarm(reported);
    },
    [openToastAlarm, queryClient, setUpdateTime],
  );

  useEffect(() => {
    if (projectCode && loggedIn && !initTime) {
      // project 변경 시 unsubscribe
      if (pubSub) {
        pubSub.unsubscribe();
        setPubSub(null);
      }

      const pubsubSubscribe = PubSub.subscribe(
        `$aws/things/MQTTBroker/shadow/name/${projectCode}_state/update/accepted`,
        {
          provider: 'AWSIoTProvider',
        },
      ).subscribe({
        next: (data: ShadowResponseType) => {
          afterReciveMqtt(data);
        },
        error: (error) => console.log('error', error),
        complete: () => console.log('Done'),
      });

      setPubSub(pubsubSubscribe);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [afterReciveMqtt, initTime, loggedIn, projectCode]);

  /**
   * logout unSubscribe
   */
  useEffect(() => {
    if (!loggedIn && pubSub && projects?.code) {
      pubSub.unsubscribe();

      if (pubSub) {
        setPubSub(null);
      }
    }
  }, [loggedIn, projects?.code, pubSub]);

  return {};
}

export default useIotCore;
