import React from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { SensorNameType, StatusType } from 'src/types/shadow';
import styled, { css } from 'styled-components';
import StatusLabel from '../_common/StatusLabel';
import { BsDot } from 'react-icons/bs';
import useSensor from 'src/hooks/useSensor';

export interface HistoryItemType {
  id: string | undefined;
  historyId: string | null | undefined;
  assetCode: string;
  assetName: string;
  address: string | undefined;
  timestamp: number;
  status: StatusType;
  sensor: any;
  active?: boolean;
  handleClickHistory: (id) => void;
}

function HistoryListItem({
  id,
  historyId,
  assetCode,
  assetName,
  address,
  timestamp,
  status,
  sensor,
  active,
  handleClickHistory,
}: HistoryItemType) {
  const { getSensorQuery, sortedSensorPriority } = useSensor();
  const { t } = useTranslation(['common']);

  // 센서 우선순위 적용
  const sortedSensorList = sortedSensorPriority(
    Object.keys(sensor) as SensorNameType[],
  );

  const dangerSensorList = sortedSensorList.filter(
    (danger) => sensor[danger].status === 'DANGER',
  );

  return (
    <HistoryItemContainer
      active={active}
      onClick={() => handleClickHistory(id)}
    >
      <HistoryItemTop>
        <div>
          <span>
            <StatusLabel status={status} />
          </span>
        </div>
        <div>{format(timestamp, 'yyyy.MM.dd HH:mm:ss')}</div>
        <div>
          <span>{assetName}</span>
        </div>
        <div>{assetCode}</div>
        <div>{address}</div>
        <div>
          {dangerSensorList.map((sensorName, i) => (
            <span key={i} className="dangerSensor">
              <span> {t(`Sensor.${sensorName}`)}</span>
              <span className="comma">{', '}</span>
            </span>
          ))}
        </div>
      </HistoryItemTop>

      <HistoryItemBottom>
        <div className="history-bottom-grid">
          {sortedSensorList.map((sensorName) => {
            const sensorValueText =
              typeof sensor[sensorName].value === 'boolean'
                ? sensor[sensorName].value
                  ? `${t('OpenStatus.open')}`
                  : `${t('OpenStatus.close')}`
                : sensor[sensorName].value;

            return (
              <span key={sensorName} className="history-sensor">
                <span className="dot">
                  <BsDot />
                </span>
                <span>{t(`Sensor.${sensorName}`)}</span>
                {sensor[sensorName].status === 'ERROR' ? (
                  <span>N/A</span>
                ) : (
                  <span className={`${sensor[sensorName].status}`}>
                    {sensorValueText}{' '}
                    {getSensorQuery.data.sensor[sensorName]?.unit}
                  </span>
                )}
              </span>
            );
          })}
        </div>
      </HistoryItemBottom>
    </HistoryItemContainer>
  );
}

export default React.memo(HistoryListItem);

const HistoryItemContainer = styled.div<{
  active?: boolean;
}>`
  ${({ active }) =>
    active
      ? css`
          & > div {
            &:last-child {
              display: block;
            }
          }
        `
      : css`
          & > div {
            &:last-child {
              display: none;
            }
          }
        `}
`;

const HistoryItemTop = styled.div`
  display: table;
  width: 100%;
  height: 50px;
  text-align: center;
  text-overflow: ellipsis;
  padding: 0 !important;
  border-bottom: 1px solid var(--border-color);
  cursor: pointer;

  div {
    width: 18%;
    display: table-cell;
    vertical-align: middle;

    &:nth-child(2) span:hover {
      color: #2196f3;
    }

    &:nth-child(1) {
      width: 8%;

      div {
        width: 48px;
        margin-right: 20px;
      }
    }

    &:last-child {
      word-break: keep-all;
    }
  }

  .dangerSensor {
    &:last-child {
      .comma {
        display: none;
      }
    }
  }
`;
const HistoryItemBottom = styled.div`
  display: none;

  .history-bottom-grid {
    border-bottom: 1px solid var(--border-color);
    padding: 20px 12% 20px 10%;

    .history-sensor {
      display: inline-block;
      text-align: center;
      margin: 30px;

      span {
        display: inline-block;
      }

      .dot {
        margin-right: 8px;
      }

      > span:last-child {
        font-weight: 500;
        padding-left: 20px;
      }

      & .DANGER {
        color: var(--chart-danger);
      }
    }
  }
`;
