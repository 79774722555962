import React, { useMemo } from 'react';
import useSensor from 'src/hooks/useSensor';
import { AssetType } from 'src/store/ManholeStore';
import { AssetSensorType, SensorNameType } from 'src/types/shadow';

function useMainSelectedSensor({
  selectedAsset,
}: {
  selectedAsset: AssetType | null;
}) {
  const { sortedSensorPriority } = useSensor();

  const selectedAssetSensor = selectedAsset?.sensor;

  // 센서 우선순위 적용 (디바이스 선택 했을 때)
  const sortedSensorList = sortedSensorPriority(
    selectedAssetSensor &&
      (Object.keys(selectedAssetSensor) as SensorNameType[]),
  );

  const selectedSortedSensors = useMemo(() => {
    if (!selectedAssetSensor) return [];
    const sensorNameValueList = sortedSensorList.reduce(
      (cur: [SensorNameType, AssetSensorType][], prev) => {
        return cur.concat([[prev, selectedAssetSensor[prev]]]);
      },
      [],
    );

    return sensorNameValueList.length % 2 === 0
      ? sensorNameValueList
      : sensorNameValueList.concat([['', {} as AssetSensorType]]);
  }, [selectedAssetSensor, sortedSensorList]);

  return { selectedSortedSensors };
}

export default useMainSelectedSensor;
