import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface AuthStoreType {
  loggedIn: boolean;
  email: string | null;
  projects?: ProjectsType | null;

  setSignIn: () => void;
  setEmail: (email: string) => void;
  setSignOut: () => void;
  setProjectArray: (projects: ProjectsType) => void;
}

export interface ProjectsType {
  code: string;
  serviceCode: string;
  name: string;
  description: string;
}

const useAuthStore = create<AuthStoreType>()(
  devtools(
    (set): AuthStoreType => ({
      loggedIn: false,
      email: null,
      projects: {
        code: '',
        serviceCode: '',
        name: '',
        description: '',
      },

      setSignIn: () => set(() => ({ loggedIn: true })),
      setEmail: (email) => set(() => ({ email })),
      setSignOut: () => set(() => ({ loggedIn: false, email: null })),
      setProjectArray: (projects) => set(() => ({ projects })),
    }),
  ),
);

export default useAuthStore;
