import { AxiosError } from 'axios';
import _ from 'lodash';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getAlarmApi,
  GetAlarmApiResponseType,
  deleteAlarmApi,
} from 'src/api/alarm/alarmApi';
import usePagination from 'src/hooks/usePagination';

import { getCookie, SERVICE_PATH } from 'src/utils/cookies';
import { useOnClickOutside } from 'usehooks-ts';
import useAuthStore from 'src/store/AuthStore';
import useManholeStore, { AssetListType } from 'src/store/ManholeStore';
const useAlarm = () => {
  const alarmRef = useRef<HTMLDivElement>(null);
  const alarmBodyRef = useRef<HTMLDivElement>(null);
  const queryClient = useQueryClient();
  const [openAlarm, setOpenAlarm] = useState<boolean>(false);
  const { loggedIn, projects } = useAuthStore();
  const { setUnselectedAsset } = useManholeStore();

  /**
   * 알람 목록 전체 개수
   */
  const [alarmTotal, setAlarmTotal] = useState<number>(0);

  /**
   * 현재 조회한 알람 목록
   */
  const [alarmList, setAlarmList] = useState<AssetListType[]>([]);

  const { page, alarmSize, handlePageChange } = usePagination();

  /**
   * 알람 param size
   */
  const [alarmParamSize, setAlarmParamSize] = useState<number>(alarmSize);
  /**
   * page Param
   */
  const pageParam = useMemo(() => {
    return { page, size: alarmParamSize };
  }, [alarmParamSize, page]);

  /**
   * 알람 더보기 클릭
   */
  const handleClickMore = useCallback(() => {
    handlePageChange(0);
    setAlarmParamSize(alarmList.length + alarmSize);

    /**
     * 전체 삭제 후 more click 시 refetch
     */
    if (alarmList.length + alarmSize === alarmSize) {
      queryClient.invalidateQueries(['alarm']);
    }
  }, [alarmList, handlePageChange, queryClient, alarmSize]);

  /**
   * 현재 조회한 알람 목록의 id 목록 For Alarm Delete
   */
  const alarmCode = useMemo(() => {
    if (_.isEmpty(alarmList)) return [];
    return alarmList.map((alarm) => alarm.id as string);
  }, [alarmList]);

  const getAlarmQuery = useQuery<GetAlarmApiResponseType, AxiosError>(
    ['alarm', pageParam],
    () => getAlarmApi(pageParam),
    {
      onSuccess: (res) => {
        setAlarmTotal(res.totalElements);

        setAlarmList(
          res.content.map((alarm) => {
            return {
              ...alarm,
              sensor: Object.keys(alarm.sensor)
                .filter(
                  (sensorName) =>
                    alarm.sensor[sensorName].status !== 'NORMAL' &&
                    alarm.sensor[sensorName].status !== 'ERROR',
                )
                .reduce((prev, cur) => {
                  return {
                    ...prev,
                    [cur]: alarm.sensor[cur],
                  };
                }, {}),
            };
          }),
        );
      },
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled: loggedIn && !!getCookie(SERVICE_PATH),
    },
  );

  const handleAlarm = () => {
    setOpenAlarm(!openAlarm);
    setUnselectedAsset();

    if (!openAlarm && _.isEmpty(alarmList)) {
      queryClient.invalidateQueries(['alarm']);
    }
  };

  const resetMoreAlarm = () => {
    setOpenAlarm(false);
    setAlarmParamSize(alarmSize);
    alarmBodyRef.current?.scrollTo({
      top: 0,
    });
  };

  useOnClickOutside(alarmRef, resetMoreAlarm);

  /**
   * Alarm 삭제 Mutation
   */
  const deleteAlarmMutation = useMutation(deleteAlarmApi, {
    onMutate: (req) => {
      return () => deleteAlarmApi(req);
    },
    onSuccess: (res, req) => {
      setAlarmTotal(alarmTotal - 1);
      if (req.isAll) {
        setAlarmList([]);
        setAlarmTotal(alarmTotal - alarmList.length);
        setAlarmParamSize(alarmSize);
      } else {
        setAlarmList(
          alarmList.filter((alarm) => alarm.id !== req.alarmCode[0]),
        );
        setAlarmTotal(alarmTotal - 1);
      }
    },
  });
  /**
   * Alarm 삭제 핸들러
   * @param id AlarmId
   */
  const handleDeleteAlarm = (id?: string) => {
    if (id) {
      deleteAlarmMutation.mutate({ alarmCode: [id] });
    }
  };

  /**
   * Alarm 전체 삭제
   */
  const handleAlarmDeleteAll = useCallback(() => {
    if (_.isEmpty(alarmCode)) return;

    deleteAlarmMutation.mutate({ alarmCode, isAll: true });
    handlePageChange(0);
  }, [alarmCode, deleteAlarmMutation, handlePageChange]);

  return {
    getAlarmQuery,
    openAlarm,
    setOpenAlarm,
    alarmRef,
    handleAlarm,
    projects,
    alarmList,
    handleDeleteAlarm,
    handlePageChange,
    page,
    handleAlarmDeleteAll,
    alarmTotal,
    handleClickMore,
    alarmBodyRef,
  };
};

export default useAlarm;
