import { getCookie } from '../../utils/cookies';
import { ApiPathPrefix } from '../apiPathPrefix';
import { afterAxios, fetchApi } from '../config';
import { AssetType } from 'src/store/ManholeStore';

/**
 * 디바이스 목록
 * @params city, state
 */
export interface GetAssetsApiRequestType {
  status?: string;
  isActive?: boolean;
}

export interface GetAssetApiResponseType {
  events: AssetType[];
}

/**
 * 디바이스 상태, 활성화에 따른 목록
 */
export const getAssetApi = async (req: GetAssetsApiRequestType) => {
  return await fetchApi({
    url: `${getCookie('MH_PATH')}${ApiPathPrefix.LATEST}`,
    method: 'GET',
    config: {
      params: { ...req },
    },
  }).then(afterAxios);
};

/**
 * 선택된 맨홀 for pin marker
 */
export const getAssetInfoApi = async (assetCode: string | undefined) => {
  return await fetchApi({
    url: `${getCookie('MH_PATH')}${ApiPathPrefix.ASSETS}/${assetCode}`,
    method: 'GET',
  }).then(afterAxios);
};

/**
 * 뚜껑 닫힘 처리
 */

export interface PatchCloseRequestType {
  assetCode: string;
}

export const patchCloseApi = async (req: PatchCloseRequestType) => {
  return await fetchApi({
    url: `blokworks-cloud/devices/${req.assetCode}/shadows/classic/close`,
    method: 'PATCH',
    data: { ...req },
  }).then(afterAxios);
};
