import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { format, subDays } from 'date-fns';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getExcelApi,
  GetExcelApiRequestType,
  getTrendApi,
  GetTrendApiRequestType,
  GetTrendApiResponseType,
} from 'src/api/trend/trendApi';
import FileSaver from 'file-saver';
import { FormAssetCodesType, TrendSearchFormType } from '../types';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import _ from 'lodash';
import { AssetType } from 'src/store/ManholeStore';

import { DEFAULT_TREND_FORM_VALUE } from '../constant';
import { getAssetApi } from 'src/api/asset/assetApi';
import { useTranslation } from 'react-i18next';

function useTrendSearch() {
  const queryClient = useQueryClient();
  const [trendAssets, setTrendAssets] = useState<any>([]);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const hookForm = useForm<TrendSearchFormType>({
    defaultValues: DEFAULT_TREND_FORM_VALUE,
  });

  const trendScrollRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation(['trend']);

  /**
   * 맨홀 선택 기본 값 지정
   */
  const selectAssetInitial = {
    allItemsAreSelected: '전체 맨홀',
    noOptions: `${t('TrendSearch.no_result')}`,
    search: `${t('TrendSearch.name_search')}`,
    selectSomeItems: `${t('TrendSearch.name_select')}`,
  };

  /**
   * param
   */

  const createTrendSearchParam = useCallback(
    (data: TrendSearchFormType) => {
      navigate({
        pathname: '/trend',
        search: `?${createSearchParams(data as string).toString()}`,
      });
    },
    [navigate],
  );

  const convertQueryParam = useCallback(() => {
    const defaultParam = {
      startDate: subDays(new Date(), 3).toISOString(),
      endDate: new Date().toISOString(),
    };

    const queryParam: any = Array.from(searchParams).reduce(
      (acc, [key, value]) => {
        if (acc[key]) {
          acc[key] = acc[key] + ', ' + value;
        } else {
          acc[key] = value;
        }
        return acc;
      },
      {},
    );

    const param = _.isEmpty(queryParam) ? defaultParam : queryParam;
    return { ...param };
  }, [searchParams]);

  const trendParam = useMemo(() => {
    const queryParam = convertQueryParam();

    const param: any = {
      ...queryParam,
    };

    return param;
  }, [convertQueryParam]);

  /**
   * param 빈 값 제거
   */

  const filterEmptyParam = useCallback((data) => {
    data.startDate = data.startDate
      ? data.startDate.toISOString()
      : subDays(new Date(), 3).toISOString();

    data.endDate = data.endDate
      ? data.endDate.toISOString()
      : new Date().toISOString();

    if (data.assetCodes) {
      data.assetCodes = data.assetCodes.map((item) => item.value).join(',');
    }

    return Object.keys(data).reduce((cur, prev) => {
      if (data[prev]) {
        cur[prev] = data[prev];
      }
      return cur;
    }, {});
  }, []);

  /**
   * asset 목록 query
   */

  const getAssetsQuery = useQuery(
    ['trend-asset'],
    () => getAssetApi({ status: '' }),
    {
      onSuccess: (res) => {
        const formData = setValueinQuery();
        const queryParam = convertQueryParam();
        if (!_.isEmpty(queryParam)) {
          hookForm.reset(formData);
        }
        setTrendAssets(res);
      },
      select: (res) => {
        return res.events
          .map((asset: AssetType) => {
            return (
              Object.keys(asset).includes('sensor') && {
                label: asset.assetName,
                value: asset.assetCode,
              }
            );
          })
          .filter(Boolean);
      },
      refetchOnWindowFocus: false,
    },
  );

  /**
   * Query Param 에서 setForm
   */
  const setValueinQuery = useCallback(() => {
    const queryParam = convertQueryParam();
    if (!_.isEmpty(queryParam) && !_.isEmpty(trendAssets)) {
      if (queryParam.startDate) {
        queryParam.startDate = new Date(
          queryParam.startDate,
        ) as unknown as string;
      }
      if (queryParam.endDate) {
        queryParam.endDate = new Date(queryParam.endDate) as unknown as string;
      }

      const assetCodes = [
        ...(trendAssets && queryParam.assetCodes
          ? trendAssets.filter((item: FormAssetCodesType) =>
              queryParam.assetCodes.includes(item.value),
            )
          : []),
      ];

      return {
        ...queryParam,
        assetCodes,
      };
    }
  }, [convertQueryParam, trendAssets]);

  /**
   * QueryParam 존재하면 셋
   */
  useEffect(() => {
    queryClient.invalidateQueries(['trend-asset']);
  }, [queryClient]);

  /**
   * 검색 초기화
   */

  const handleTrendClearForm = useCallback(() => {
    hookForm.reset(DEFAULT_TREND_FORM_VALUE);
    navigate({
      pathname: '/trend',
    });
  }, [hookForm, navigate]);

  /**
   * 검색
   */

  const handleTrendSearch = useCallback(
    (data) => {
      const convertParam = filterEmptyParam(data);

      createTrendSearchParam(convertParam);
    },
    [createTrendSearchParam, filterEmptyParam],
  );

  /**
   * 엑셀 다운로드
   */

  const excelDownloadMutation = useMutation(getExcelApi, {
    onMutate: (req: GetExcelApiRequestType) => {
      return () => getExcelApi(req);
    },
    onSuccess: async (res, req) => {
      const blob = new Blob([res], {
        type: 'text/xlsx;charset=utf-8',
      });
      FileSaver.saveAs(
        blob,
        `${format(
          new Date(req.startDate || subDays(new Date(), 3).toISOString()),
          'yyyy.MM.dd HH:mm:ss',
        )}-${format(
          new Date(req.endDate || new Date().toISOString()),
          'yyyy.MM.dd HH:mm:ss',
        )}.xlsx`,
      );
    },
  });

  const handleExcelDownload = useCallback(
    (timeZone) => {
      const watchData = hookForm.watch();
      const convertParam = filterEmptyParam(watchData);
      excelDownloadMutation.mutate({
        ...convertParam,
        timeZone,
      });
    },
    [excelDownloadMutation, filterEmptyParam, hookForm],
  );

  /**
   * 트렌드 목록
   */

  const getTrendQuery = useQuery<GetTrendApiResponseType>(
    ['trend', trendParam],
    () => getTrendApi(trendParam as unknown as GetTrendApiRequestType),
  );

  return {
    hookForm,
    selectAssetInitial,
    handleTrendClearForm,
    handleTrendSearch,
    getTrendQuery,
    excelDownloadMutation,
    handleExcelDownload,
    getAssetsQuery,
    trendAssets,
    trendScrollRef,
  };
}

export default useTrendSearch;
