import React from 'react';
import { useTranslation } from 'react-i18next';
import useSensor from 'src/hooks/useSensor';
import styled from 'styled-components';
import useAssetsList from '../MainAssetsList/hooks/useAssetsList';

export interface SelectedSensorItemType {
  assetCode?: string;
  sensor: any[];
}

function MainSelectedSensorItem({ assetCode, sensor }: SelectedSensorItemType) {
  const { getSensorQuery } = useSensor();
  const sensorName = sensor[0];
  const { handleClickClose } = useAssetsList();
  const { t } = useTranslation(['common']);

  return (
    <SelectedSensorItemContainer>
      <SensorTitle>
        <div className="title">{sensorName && t(`Sensor.${sensorName}`)}</div>
        <div className="unit">
          {getSensorQuery.data.sensor[sensorName]?.unit}
        </div>
      </SensorTitle>
      <SensorData
        className={`sensor_status ${sensor[1].status?.toUpperCase()}`}
      >
        {sensor[1].status === 'ERROR' ? (
          'N/A'
        ) : typeof sensor[1].value === 'boolean' ? (
          sensor[1].value ? (
            <div
              onClick={() => {
                handleClickClose(assetCode);
              }}
            >
              <span className="button open">{t('OpenStatus.open')}</span>
              <span className="button close_button">
                {t('OpenStatus.set_close')}
              </span>
            </div>
          ) : (
            <div>
              <span className="close">{t('OpenStatus.close')}</span>
            </div>
          )
        ) : (
          sensor[1].value
        )}
      </SensorData>
    </SelectedSensorItemContainer>
  );
}

export default MainSelectedSensorItem;

const SelectedSensorItemContainer = styled.div`
  width: 140px;
  height: 80px;
  border-bottom: 1px solid #e4e5e6;
  border: 1px solid #e4e5e6;
  box-sizing: border-box;
  padding: 5px;
  background-color: #fff;
`;

const SensorTitle = styled.div`
  text-align: right;
  font-size: 14px;
  margin-right: 4px;
  line-height: 18px;

  .unit {
    font-weight: 500;
  }
`;

const SensorData = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 500;

  > div {
    span {
      display: inline-block;
      width: 90px;
      padding: 5px 0;
      margin-top: 5px;
      border-radius: 10px;
      box-sizing: border-box;
    }

    .open {
      border: 1px solid var(--danger-badge-background-color);
      cursor: pointer;
    }
    .close_button {
      display: none;
    }
    .close {
      border: 1px solid #000;
    }
  }

  &.DANGER {
    color: var(--danger-badge-background-color);

    > div:hover {
      cursor: pointer;
      .open {
        display: none;
      }
      .close_button {
        display: inline-block;
        color: #000;
        border: 1px solid #000;
      }
    }
  }
`;
