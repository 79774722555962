import React, { lazy, useEffect } from 'react';
import i18n from './i18n';
import useIotCore from './hooks/useIotCore';
import useMqtt from './hooks/useMqtt';
import BaseRoute from './BaseRoute';

function App() {
  useEffect(() => {
    if (navigator.language) {
      i18n.changeLanguage(navigator.language);
    }
  }, []);

  const server =
    window._env_.REACT_APP_ENVIRONMENT === 'ONPREMISE' ? useMqtt : useIotCore;

  server();

  return <BaseRoute />;
}

export default App;
