import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartOptions,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

interface DoughnutChartPropsType {
  chartData: number[];
  tooltipList: any[];
  isTrend: boolean;
}

function DoughnutChart({
  chartData,
  tooltipList,
  isTrend,
}: DoughnutChartPropsType) {
  const { t } = useTranslation(['common', 'home']);

  const data = {
    labels: isTrend
      ? [
          `${t('Status.NORMAL')}`,
          `${t('Status.WARNING')}`,
          `${t('Status.DANGER')}`,
          '데이터 없음',
        ]
      : [
          `${t('Status.NORMAL')}`,
          `${t('Status.WARNING')}`,
          `${t('Status.DANGER')}`,
        ],

    datasets: [
      {
        data: chartData,
        borderWidth: 2,
        backgroundColor: ['#15b84b', '#ffc400', '#ff6a80', '#dbdbdb'],
        cutout: '50%',
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: !isTrend,
        position: 'bottom',
        labels: {
          boxWidth: 12,
          boxHeight: 12,
        },
      },
      tooltip: {
        filter: function (tooltipItem, data) {
          return tooltipItem.label !== '데이터 없음';
        },
        callbacks: {
          afterFooter: (context) => {
            return tooltipList.map((status, i) => {
              return `${t(`Sensor.${status[0]}`)} :  ${
                context[0].label === `${t('Status.NORMAL')}`
                  ? status[1]
                  : context[0].label === `${t('Status.WARNING')}`
                  ? status[2]
                  : status[3]
              }`;
            });
          },
        },
      },
      datalabels: {
        display:
          !isTrend &&
          function (context) {
            return context.dataset.data[context.dataIndex] > 0;
          },
        color: 'white',
        font: {
          weight: 'bold',
        },
      },
    },
  };

  const textCenter = {
    id: 'textCenter',
    beforeDatasetsDraw(chart, args, pluginOptions) {
      const { ctx, data } = chart;

      ctx.save();
      ctx.font = '20px';
      ctx.fillStyle = 'black';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(
        isTrend
          ? ''
          : `${t('ManholeStatus.all', { ns: 'home' })} ${
              data.datasets[0].data[0] +
              data.datasets[0].data[1] +
              data.datasets[0].data[2]
            }${t('ManholeStatus.count', { ns: 'home' })}`,
        chart.getDatasetMeta(0).data[0].x,
        chart.getDatasetMeta(0).data[0].y,
      );
    },
  };

  return (
    <ChartContainer>
      <Doughnut
        data={data}
        options={options as ChartOptions<'doughnut'>}
        plugins={[textCenter]}
      />
    </ChartContainer>
  );
}

export default DoughnutChart;

const ChartContainer = styled.div`
  box-sizing: border-box;
  padding: 30px 0 20px 0;
`;
