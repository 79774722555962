import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import DoughnutChart from 'src/components/_common/DoughnutChart';
import useMain from '../hooks/useMain';

function MainTotalAssets() {
  const { totalListCount } = useMain();
  const { t } = useTranslation(['home']);

  return (
    <MainTotalAssetsContainer>
      <MainStatusTitle>{t('ManholeStatus.title')}</MainStatusTitle>
      <MainChart>
        <SensorChart>
          <ChartTitle>{t('ManholeStatus.status')}</ChartTitle>
          <ChartBox>
            <DoughnutChart
              chartData={[
                totalListCount.NORMAL,
                totalListCount.WARNING,
                totalListCount.DANGER,
              ]}
              tooltipList={[]}
              isTrend={false}
            />
          </ChartBox>
        </SensorChart>

        <AssetChart>
          <ChartTitle className="device-chart">
            {t('ManholeStatus.activeStatus')}
          </ChartTitle>
          <ChartBox>
            <AssetsBar>
              <InActiveBar
                percent={(totalListCount.INACTIVE / totalListCount.ALL) * 100}
              >
                <div className="chart-text">
                  {t('ManholeStatus.inactive')} ({totalListCount.INACTIVE})
                </div>
              </InActiveBar>
              <ActiveBar
                percent={
                  ((totalListCount.ALL - totalListCount.INACTIVE) /
                    totalListCount.ALL) *
                  100
                }
              >
                <div className="chart-text">
                  {t('ManholeStatus.active')} (
                  {totalListCount.ALL - totalListCount.INACTIVE})
                </div>
              </ActiveBar>
            </AssetsBar>
          </ChartBox>
        </AssetChart>
      </MainChart>
    </MainTotalAssetsContainer>
  );
}

const MainTotalAssetsContainer = styled.div`
  background-color: #fff;
  margin-bottom: 20px;
`;
const MainStatusTitle = styled.div`
  font-weight: 500;
  padding: 12px 12px 12px 20px;
  line-height: 1.25;
  border-bottom: 1px solid #e4e5e6;
`;

const MainChart = styled.div`
  display: flex;
`;

const SensorChart = styled.div`
  width: 75%;
  border-right: 1px solid #e4e5e6;
  box-sizing: border-box;
`;
const AssetChart = styled.div`
  width: 25%;
`;

const ChartTitle = styled.div`
  border-bottom: 1px solid #e4e5e6;
  padding: 4px 0 4px 20px;

  &.asset-chart {
    text-align: center;
    padding-left: 0;
  }
`;

const ChartBox = styled.div`
  box-sizing: border-box;
`;

const AssetsBar = styled.div`
  width: 66px;
  height: 180px;
  margin: 10px auto;
  background-color: #aaadad;
  font-size: 12px;
  text-align: center;
  opacity: 0.9;
  font-weight: 500;
  color: #fff;

  .chart-text {
    display: table-cell;
    vertical-align: middle;
  }
`;
const InActiveBar = styled.div<{ percent: number }>`
  display: ${({ percent }) => (percent ? 'table' : 'none')};
  width: 100%;
  height: ${({ percent }) => percent && `${percent}%`};
`;
const ActiveBar = styled.div<{ percent: number }>`
  display: ${({ percent }) => (percent ? 'table' : 'none')};
  width: 100%;
  height: ${({ percent }) => percent && `${percent}%`};
  background-color: var(--chart-normal);
`;

export default MainTotalAssets;
