import axios, { HeadersDefaults } from 'axios';
import { SignInResponseType } from '../api/oAuth/oAuthApi';
import {
  ACCESS_TOKEN_NAME,
  ID_TOKEN_NAME,
  REFREASH_TOKEN_NAME,
  setCookie,
} from './cookies';

export interface CommonHeaderProperties extends HeadersDefaults {
  idtoken?: string;
}

export function setToken(
  loginResponse: SignInResponseType,
  isRefresh?: boolean,
) {
  const { accessToken, idToken, refreshToken } = loginResponse;
  axios.defaults.headers = {
    ...axios.defaults.headers,
  } as CommonHeaderProperties;

  const expires = new Date();
  expires.setDate(Date.now() + 1000 * 60 * 60 * 24);

  setCookie(ACCESS_TOKEN_NAME, accessToken, {
    path: '/',
    expires,
  });
  setCookie(ID_TOKEN_NAME, idToken, {
    path: '/',
    expires,
  });

  if (!isRefresh) {
    setCookie(REFREASH_TOKEN_NAME, refreshToken, {
      path: '/',
      expires,
    });
  }
}

/**
 * Token decode
 */
export const decodeToken = (token: string) => {
  const base64Payload = token.split('.')[1];
  const payload = Buffer.from(base64Payload, 'base64');
  // const base64DecodedText = Buffer.from(base64Payload, 'base64').toString(
  //   'utf8',
  // );

  return JSON.parse(payload.toString());
};
