import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import HistoryListItem from './HistoryListItem';
import Pagination from './Pagination';

interface UseHistoryListType {
  handleClickHistory: (id: string) => void;
  page: number;
  size: number;
  handlePageChange: (newPage: number) => void;
  getHistoryQuery: any;
  historyId: string | null;
}

function HistoryList({
  handleClickHistory,
  page,
  size,
  handlePageChange,
  getHistoryQuery,
  historyId,
}: UseHistoryListType) {
  const { t } = useTranslation(['history']);

  return (
    <HistoryContainer>
      {/* title */}
      <HistoryHead>
        <StateTitle>{t('Table.title')}</StateTitle>
      </HistoryHead>

      {/* table */}
      <TableWrap>
        <TableDiv>
          <TableHead>
            <div className="table-head">
              <div>{t('Table.status')}</div>
              <div>{t('Table.time')}</div>
              <div>{t('Table.name')}</div>
              <div>{t('Table.code')}</div>
              <div>{t('Table.location')}</div>
              <div>{t('Table.content')}</div>
            </div>
          </TableHead>
          <TableBody>
            {getHistoryQuery.isLoading ? (
              <></>
            ) : getHistoryQuery.data &&
              !_.isEmpty(getHistoryQuery.data.content) ? (
              getHistoryQuery.data.content.map((history, i) => {
                return (
                  <HistoryListItem
                    key={history.id}
                    id={history.id}
                    historyId={historyId}
                    assetCode={history.assetCode}
                    assetName={history.assetName}
                    address={history.geoLocation?.address}
                    timestamp={history.timestamp}
                    status={history.status}
                    sensor={history.sensor}
                    active={historyId === history.id}
                    handleClickHistory={handleClickHistory}
                  />
                );
              })
            ) : (
              <NoContentDiv>{t('Table.no_content')}</NoContentDiv>
            )}
          </TableBody>
        </TableDiv>

        {/* pagination */}
        {getHistoryQuery.isSuccess &&
          getHistoryQuery.data?.totalElements !== 0 && (
            <Pagination
              total={getHistoryQuery.data?.totalElements || 0}
              size={size}
              page={page}
              handlePageChange={handlePageChange}
            />
          )}
      </TableWrap>
    </HistoryContainer>
  );
}

export default React.memo(HistoryList);

const HistoryContainer = styled.div`
  width: 100%;
  height: calc(100% - 60px);
  background-color: #fff;
  padding: 16px 24px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const HistoryHead = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 16px;
`;

const StateTitle = styled.h3`
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  line-height: 44px;
`;

const TableWrap = styled.div`
  width: 100%;
  margin: auto;
  overflow-y: auto;
  font-size: 14px;
  min-height: 60vh;
`;

const TableDiv = styled.div`
  width: 100%;
  border: 1px solid var(--border-color);
  border-bottom: none;
  box-sizing: border-box;
`;

const TableHead = styled.div`
  background-color: var(--hover-background-color);

  .table-head {
    display: table;
    width: 100%;
    text-align: center;
    text-overflow: ellipsis;
    height: 50px;
    padding: 0 !important;
    font-weight: 500;
  }
  .table-head > div {
    width: 18%;
    display: table-cell;
    vertical-align: middle;

    &:nth-child(1) {
      width: 8%;
    }
  }
`;
const TableBody = styled.div``;

const NoContentDiv = styled.div`
  padding: 12px;
  box-sizing: border-box;
  text-align: center;
  border-bottom: 1px solid var(--border-color);
`;
