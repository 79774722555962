import { AssetListType } from 'src/store/ManholeStore';
import { DefaultPaginationType } from 'src/types/pagination';
import { getCookie } from 'src/utils/cookies';
import { ApiPathPrefix } from '../apiPathPrefix';
import { afterAxios, fetchApi } from '../config';

export interface GetEventListRequestType {
  status?: string;
  startDate?: string;
  endDate?: string;
  assetName?: string;
  assetCode?: string;
  page?: number;
  size?: number;
  isAlarm?: boolean;
  historyId?: string;
}

export const getHistoryApi = async (
  req: GetEventListRequestType,
): Promise<DefaultPaginationType<AssetListType>> => {
  return await fetchApi({
    url: `${getCookie('MH_PATH')}${ApiPathPrefix.HISTORY}`,
    method: 'GET',
    config: {
      params: { ...req },
    },
  }).then(afterAxios);
};
