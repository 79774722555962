import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ja from './locales/ja';
import ko from './locales/ko';
import en from './locales/en';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
  // .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    partialBundledLanguages: true,
    resources: {
      ko,
      ja,
      en,
    },
    fallbackLng: 'ko',
    interpolation: {
      escapeValue: false,
    },
    ns: ['login', 'header', 'home', 'trend', 'history', 'common'],
    detection: {
      order: ['navigator'],
    },
    // backend: {
    //   loadPath: function (lng, ns) {
    //     return `https://mahole-locales-test.s3.ap-northeast-2.amazonaws.com/locales/${
    //       lng[0]
    //     }/${ns[0]}.json?timestamp=${new Date().toString}`;
    //   },
    //   crossDomain: true,
    // },
  });

export default i18n;
