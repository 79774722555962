import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Layer, Stage, Text, Image as KnovaImage, Group } from 'react-konva';
import useImage from 'use-image';
import _ from 'lodash';
import { SizeType } from 'src/utils/image';
import useManholeStore from 'src/store/ManholeStore';

interface MapCanvasPropsType {
  size: { width: number; height: number };
  imageUrl: string;
}

const MARKER_WIDTH = 27;
const MARKER_HEIGHT = 45;

export interface MakerType {
  assetCode: string;
  assetName: string;
  x: number;
  y: number;
  status: string;
  active: boolean;
}

const MarkerImage = ({ marker, ...props }: any) => {
  const [image] = useImage(
    `${process.env.PUBLIC_URL}/img/icon/${
      marker.active ? marker.status.toLowerCase() : 'inactive'
    }-marker.svg`,
  );

  return <KnovaImage image={image} {...props} />;
};

function MapCanvas({ size, imageUrl }: MapCanvasPropsType) {
  const { assets, selectedAsset, setSelectedAsset } = useManholeStore();

  const [mapImage] = useImage(imageUrl);

  const getSizeOriginImage = useCallback((imagePath: string) => {
    const img = new Image();
    img.onload = function () {
      setOriginImageSize({
        width: img.width,
        height: img.height,
      });
    };
    img.onerror = function () {
      console.log('Error loading the image.');
    };
    img.src = imagePath;
  }, []);

  useEffect(() => {
    if (imageUrl) {
      getSizeOriginImage(imageUrl);
    }
  }, [getSizeOriginImage, imageUrl]);

  const [markerList, setMarkerList] = useState<MakerType[]>([]);

  const [originImageSize, setOriginImageSize] = useState<SizeType>({
    width: 0,
    height: 0,
  });

  const scaleXY = useMemo(() => {
    return {
      scaleX: size.width / originImageSize.width,
      scaleY: size.width / originImageSize.width,
    };
  }, [originImageSize.width, size.width]);

  const markerSize = useMemo(() => {
    // const upScale =
    //   scaleXY.scaleX > 1 ? scaleXY.scaleX / 10 : scaleXY.scaleX * 5;

    return {
      width: MARKER_WIDTH,
      height: MARKER_HEIGHT,
    };
  }, []);

  useEffect(() => {
    if (!_.isEmpty(assets) && size.width !== 0 && originImageSize.width !== 0) {
      setMarkerList(
        assets
          .filter(
            (asset) =>
              asset?.geoLocation?.gps &&
              asset?.geoLocation?.gps[0] !== -1 &&
              asset?.geoLocation?.gps[1] !== -1,
          )
          .map((asset, index) => {
            return {
              assetCode: asset.assetCode,
              assetName: asset.assetName,
              x: asset?.geoLocation?.gps
                ? asset.geoLocation.gps[0] * scaleXY.scaleX
                : -1,
              y: asset.geoLocation?.gps
                ? asset.geoLocation.gps[1] * scaleXY.scaleY
                : -1,
              status: asset.status,
              active: asset.active,
            };
          }),
      );
    }
  }, [assets, originImageSize, scaleXY.scaleX, scaleXY.scaleY, size]);

  const handleClickMarker = useCallback(
    (assetCode: string) => {
      const findAsset = assets.find((asset) => asset.assetCode === assetCode);
      if (findAsset) setSelectedAsset(findAsset);
    },
    [assets, setSelectedAsset],
  );

  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <Stage
        width={size.width}
        height={size.height}
        onMouseDown={(e) => {
          setSelectedAsset(null);
        }}
      >
        <Layer>
          <KnovaImage
            alt="image"
            image={mapImage}
            width={originImageSize.width * scaleXY.scaleX}
            height={originImageSize.height * scaleXY.scaleY}
          />

          {!_.isEmpty(markerList) &&
            markerList.map((marker) => {
              return (
                <Group key={marker.assetCode}>
                  <MarkerImage
                    marker={marker}
                    alt="marker"
                    x={marker.x}
                    y={
                      marker.y -
                      (selectedAsset &&
                      selectedAsset?.assetCode === marker.assetCode
                        ? 20
                        : 0)
                    }
                    onClick={() => handleClickMarker(marker.assetCode)}
                    width={markerSize.width}
                    height={markerSize.height}
                    scaleX={
                      selectedAsset?.assetCode === marker.assetCode ? 1.3 : 1
                    }
                    scaleY={
                      selectedAsset?.assetCode === marker.assetCode ? 1.3 : 1
                    }
                  />
                  <Text
                    text={marker.assetName}
                    x={marker.x - MARKER_WIDTH}
                    y={marker.y + MARKER_HEIGHT}
                  />
                </Group>
              );
            })}
        </Layer>
      </Stage>
    </div>
  );
}

export default MapCanvas;
